/**
 * Created by dwiargo on 12/13/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper,LinearProgress } from 'react-md';
import './Panel.scss';

class Panel extends Component {
  render() {
    let {title, children, className, smallHeader, headerStyle={}, style={}} = this.props;
    return (
      <Paper 
        className={"mpk-panel "+ (className ? className : "")+ " " + (smallHeader ? "small-header" : "")}
        style={style}
      >
        <div 
          className="header mpk-layout align-center mpk-border solid bottom dark flex-none" 
          style={headerStyle}>
          <div className="flex">{title}</div>
          {this.props.headerAction ? (this.props.headerAction) : (null)}
          {this.props.onProgress ? (<LinearProgress id="mpk-panel-linear-progress"/>) : (null)}
        </div>
        <div className="body body-text flex">{children}</div>
      </Paper>
    )
  }
}
Panel.propTypes = {
  title:PropTypes.string.isRequired,
  onProgress:PropTypes.bool,
  children:PropTypes.node,
  smallHeader:PropTypes.bool
}

export default Panel;