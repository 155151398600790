import React from 'react';
import { merge } from 'lodash';
import * as service from '../../service';
import { httpService, rdxConnect, SearchField, Pagination, LoaderInfo, NotFoundData } from 'libs/react-mpk';
import t from 'counterpart';
import { Button, DataTable, TableBody, TableHeader, TableColumn, TableRow, MenuButton, FontIcon, ListItem } from 'react-md';
import Form from './Form';
import { cloneDeep } from 'lodash';
import { utilsService } from 'services';

class BankAccount extends React.Component{
  constructor(){
    super();
    this.state = {
      data: [],
      isLoading: false,
      showForm: false,
      selectedItem: null,
      query: {
        page: 1,
        size: 20,
        keyword: '',
        column: '',
        sort: 'DESC',
        sortBy: 'transactionDateTime',
        total: 0
      }
    }
  }

  componentDidMount(){
    this.fetchData();
  }

  fetchData = async (newQuery = {}) => {
    let { query } = this.state;
    query = merge(query, newQuery);
    query.total = 0;
    this.setState({isLoading: true, query})
    try {
      let data = [];
      let res = await service.getBank(query, item => {
        data.push(item);
        this.setState({data})
      });

      query.total = Number(res.headers['x-pagination-count']);
      this.setState({query, isLoading: false});
    } catch (error) {
      this.props.toastActions.izi(
        t.translate('word.failed'),
        httpService.utils.parseErrorMessage(error, this.props.global.localeCode),
        'warning'
      )
    }
  }

  editItem = item => {
    let selectedItem = cloneDeep(item);
    selectedItem.bankId = selectedItem.bank.id;
    this.setState({showForm: true, selectedItem})
  }

  updateData = item => {
    let { data } = this.state;
    data = utilsService.updateDataItemInArray(data, {id: item.id}, item);
    this.setState({data});
  }

  deleteItem = item => {
    this.props.dialogActions.showConfirm(
      t.translate('sentence.dialog.delete.title'),
      t.translate('sentence.dialog.delete.message'),
      async callback => {
        try {
          await service.deleteBank(item.id);
          let { data, query } = this.state;
          query -= 1;
          data = utilsService.deleteDataItemInArray(data, {id: item.id})
          this.setState({data, query});
          callback();
        } catch (error) {
          callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode))
        }
      }
    )
  }

  render(){
    let { query, isLoading, data } = this.state;
    let columns = [
      {
        label: '#',
        value: (d, i) => (
          (i+1)+(query.size*(query.page-1))
        )
      },
      {
        label: t.translate('column.accountName'),
        value: (d) => (<div className="mpk-link" onClick={() => this.editItem(d)}>{d.accountName}</div>)
      },
      {
        label: t.translate('column.accountNo'),
        value: (d) => (d.accountNo)
      },
      {
        label: t.translate('column.bank'),
        value: (d) => (
          <div>
            <div className="mpk-font-weight-M">{d.bank.name}</div>
            <div className="mpk-font-size-S mpk-font-color-D3 mpk-margin-S marign-top">{d.bank.description}</div>
          </div>
        )
      },
      {
        label: t.translate('word.actions'),
        value: (d) => (
          <div className="mpk-layout align-center">
            <MenuButton
              id="mpk-table-action-item"
              icon
              menuItems={[
                {
                  label: 'Edit',
                  iconClassName: "mdi mdi-pencil",
                  onClick: () => this.editItem(d),
                  show: true
                },
                {
                  label: t.translate('word.delete'),
                  iconClassName: 'mdi mdi-delete',
                  show: this.props.auth.hasPermission('DELETE:/user_bank/*'),
                  onClick: () => this.deleteItem(d)
                }
              ].filter(m => (m.show)).map(m => (
                <ListItem
                  key={`menu-item`}
                  onClick={m.onClick}
                  primaryText={m.label}
                  leftIcon={
                    <FontIcon iconClassName={m.iconClassName}/>
                  }
                />
              ))}
              iconClassName="mdi mdi-dots-vertical"
            />
          </div>
        )
      },
    ]

    return(
      <div className="mpk-layout column mpk-full viewport-width height mpk-animation slide-in">
        <div 
          className="mpk-border thin solid border-bottom dark mpk-padding-N padding-all mpk-layout align-center justify-between"
          style={{
            height: 48
          }}
        >
          <div className="mpk-layout align-center mpk-position">
            <SearchField 
              withBorder={false}
              onSubmit={keyword => this.fetchData({keyword, page: 1})}
            />
            <Pagination 
              {...query}
              onChange={ page => this.fetchData({page})}
            />
          </div>
          <div className="mpk-layout align-center">
            <Button 
              icon iconClassName="mdi mdi-reload"
              primary onClick={() => this.fetchData()}
              mini style={{width: 40, height: 40}}
              className="mpk-margin-S margin-right"
            />
            <Button
              primary raised
              iconClassName="mdi mdi-plus"
              className="mpk-margin-NONE margin-all"
              onClick={() => this.setState({showForm: true, selectedItem: null})}
              mini style={{width: 56, height: 40}}
            />
          </div>
        </div>
        { isLoading ? <LoaderInfo/> : (
          query.total === 0 ? <div className="mpk-padding-M padding-top padding-bottom"><NotFoundData/></div> : (
            <DataTable
              className="flex"
              baseId="ub-bank-table"
              selectableRows={false}
            >
              <TableHeader>
                <TableRow>
                  {columns.map(col => (
                    <TableColumn
                      key={`header-transaction-list-${col.label}`}
                    >
                      {col.label}
                    </TableColumn>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((d,i) => (
                  <TableRow key={`body-transaction-row-${i}`}>
                    {columns.map(col => (
                      <TableColumn key={`body-transaction-row-${i}-col-${col.label}`}>
                        {col.value(d, i)}
                      </TableColumn>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
          )
        )}
        <Form
          visible={this.state.showForm}
          onCancel={() => this.setState({showForm: false, selectedItem: null})}
          item={this.state.selectedItem}
          onSuccess={this.updateData}
        />
      </div>
    )
  }
}

export default rdxConnect(BankAccount);