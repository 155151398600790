import React from 'react';
import { TextField } from 'react-md';
import Empty from '../Empty';
import moment from 'moment';
import t from 'counterpart';

const dayOfMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
class DateTimeField extends React.Component{
  state = {
    refValue: null,
    date:{
      date: null,
      month: null,
      year: null,
      hour: null,
      minute: null,
      second: null
    }
  }

  compnentWillMount(){
    this.initDate(this.props.defaultValue);
  }

  componentDidUpdate(prevProps, prevState){
    let { defaultValue } = prevProps;
    if(defaultValue !== prevState.refValue && prevState.refValue === null) this.initDate(defaultValue);
  }

  initDate = (value=new Date()) => {
    let date = value.getDate();
    let month = value.getMonth()+1;
    let year = value.getFullYear();
    let hour = value.getHours();
    let minute = value.getMinutes();
    let second = value.getSeconds();
    this.setState({date:{date, month, year, hour, minute, second}, refValue: value});
  }

  onChange = (key, value) => {
    let { date } = this.state;
    value = value.replace(/\D/g, '');
    const handler = {
      date: (val) => {
        let idxMonth = date.month - 1;
        let isKabisat = date.year ? date.year%4===0 : false;
        let maxDate = date.month ? (isKabisat && idxMonth === 1 ? 29 : dayOfMonths[idxMonth]) : 31
        date.date = val > maxDate ? maxDate : val;
        date.date = date.date < 1 ? null : date.date;
      },
      month: (val) => {
        date.month = val > 12 ? 12 : val;
        date.month = date.month < 1 ? null : date.month;
        handler.date(date.date);
      },
      year: (val) => {
        date.year = val < 0 ? 0 : val;
        handler.date(date.date);
      },
      hour: (val) => {
        date.hour = val > 23 ? 23 : val;
        date.hour = date.hour < 0 ? 0 : date.hour;
      },
      minute: (val) => {
        date.minute = val > 59 ? 59 : val;
        date.minute = date.minute < 0 ? 0 : date.minute;
      },
      second: (val) => {
        date.second = val > 59 ? 59 : val;
        date.second = date.second < 0 ? 0 : date.second;
      }
    }

    handler[key](value);
    this.setState({date}, () => {
      let { date, month, year, hour, minute, second } = this.state.date;
      const _date = moment(`${date}/${month}/${year} ${hour}:${minute}:${second}`, 'DD/MM/YYYY HH:mm:ss').toDate();
      this.props.onChange(_date)
    });
  }

  render(){
    let { className='', showDate, showTime, id, disabled, readOnly } = this.props;
    return(
      <div>
        <div className={`mpk-layout align-center ${className}`}>
          {showDate && (
            <div className="mpk-layout align-center">
              {['date', 'month', 'year'].map((key, i) => (
                <div className="mpk-layout align-end">
                  <TextField
                    key={`${id}-${key}`}
                    label={t.translate(`word.${key}`)}
                    className="mpk-flex"
                    value={this.state.date[key]}
                    onChange={(value) => this.onChange(key, value)}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                  {i < 2 && <div className="mpk-margin-S margin-right margin-left mpk-padding-S padding-bottom">/</div>}
                </div>
              ))}
            </div>
          )}
          {showDate && showTime && <Empty width={24}/>}
          {showTime && (
            <div className="mpk-layout align-center">
              {['hour', 'minute', 'second'].map((key, i) => (
                <div className="mpk-layout align-end">
                  <TextField
                    key={`${id}-${key}`}
                    label={t.translate(`word.${key}`)}
                    className="mpk-flex"
                    value={this.state.date[key]}
                    onChange={(value) => this.onChange(key, value)}
                    disabled={disabled}
                    readOnly={readOnly}
                  />
                  {i < 2 && <div className="mpk-margin-S margin-right margin-left mpk-padding-S padding-bottom">:</div>}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

DateTimeField.defaultProps = {
  id: 'mpk-date-time-field',
  value: new Date(),
  showDate: true,
  showTime: true,
  onChange:() => {}
}

export default DateTimeField;