import React from 'react';
import tableWrapper from 'components/tableWrapper';
import t from 'counterpart';
import * as service from './service';

class TopUp extends React.Component{
  render(){
    return(
      <div className="mpk-full height mpk-padding-N padding-all">
        {this.props.children}
      </div>
    )
  }
}

export default tableWrapper((props, handle) => {
  let { userBalanceAccount } = props.tmpObject;
  return {
    hint:{
      children: userBalanceAccount 
        ? t.translate('routes.userBalance.transaction.hint.userTopUpText').replace('*name', userBalanceAccount.userInfo.username)
        : t.translate('routes.userBalance.transaction.hint.topUptext')
    },
    columns: [
      {
        label: t.translate('column.requestDateTime'),
        value: 'dateTime',
        isSearchable: false,
        isSortable: true
      },
      {
        label: t.translate('column.bank'),
        value: 'bank',
        isSearchable: true,
        isSortable: true
      },
      {
        label: t.translate('column.accountNo'),
        value: 'accountNo',
        isSearchable: true,
        isSortable: true
      },
      {
        label: t.translate('column.accountName'),
        value: 'accountName',
        isSearchable: true,
        isSortable: true
      }
    ],
    itemActions: [
      {
        label:"Edit",
        iconClassName:"mdi mdi-pencil",
        onClick: item => handle.showForm(true, item),
      }
    ],
    fetchData: (params, onDataItem) => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await service.getTopUp(params, onDataItem)
          resolve({data: [], total: Number(res.headers['x-pagination-count'])});
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
  }
})(TopUp)