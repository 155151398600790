/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, DropdownMenu, AccessibleFakeButton, FontIcon, CircularProgress } from 'react-md';
import AppbarProfile from './AppbarProfile';
import Translate from 'react-translate-component';

import * as globalActions from '../../redux/actions/globalActions';

import Logo from '../Logo';
import AppLogo from '../AppLogo';

import navService from '../../services/navService';
import env from '../../config/env';

import './Appbar.scss';

class Appbar extends Component {
  toggleMenu = () => {
    this.props.globalActions.toggleProperty('appbarLeftActionToggled');
  }

  render() {
    let { auth, translate, onLogout, appName, onLogoClick, subname } = this.props;
    let isFixed = '';//window.location.pathname === '/main' ? 'fixed' : '';

    const generateNav = (d, i) => (
      <Button
        flat
        className={"nav-item "+ (window.location.pathname === d.path ? 'active' : '')}
        key={i}
        onClick={() => navService.redirectTo(d.path)}
      >
        {translate ? (<Translate content={d.label}/>) : (d.label)}
      </Button>
    );

    const GetLogo = (
      <div
        className="mpk-layout column align-center"
        onClick={() => {
          if(onLogoClick) onLogoClick();
          else this.toggleMenu();
        }}
      >
        { appName !== null && appName !== undefined && appName !== '' && env.theme === env.themes.PAJAKKU ? (
          <div className="mpk-layout column align-center">
            <AppLogo
              className="appbar-logo"
              appName={appName}
              subname={subname}
            />
            <AppLogo
              className="appbar-logo"
              light
              appName={appName}
              subname={subname}
            />
          </div>
        ) : (
          <div className="mpk-layout column align-center">
            <Logo 
              className="appbar-logo"
              light subname={env.theme === env.themes.SOBATPAJAK ? ( appName ? env.mappingApps[appName] : subname ) : subname} style={{
                width:env.theme === env.themes.PAJAKKU ? 96 : 140
              }}
              />
            <Logo 
              className="appbar-logo"
              subname={env.theme === env.themes.SOBATPAJAK ? ( appName ? env.mappingApps[appName] : subname ) : subname} 
              style={{
                width:env.theme === env.themes.PAJAKKU ? 96 : 140
              }}
            />
          </div>
        )}
      </div>

    )

    return (
      <div className={"mpk-appbar mpk-layout align-center justify-between "+isFixed}>
        <div className="mpk-layout align-center">
          { this.props.global.appbarLeftActionVisible ? (
            <div className="flex-none mpk-show-xs" style={{marginRight:8}}>
              <Button
                icon
                iconClassName="mdi mdi-menu"
                className="appbar-button"
                onClick={this.toggleMenu}
                />
            </div>
          ) : (null)}
          { this.props.logo ? (this.props.logo) : (
            GetLogo
          )}
          { this.props.navigation ? (
            <div className="navsGroup">
              <div className="navs gt-sm mpk-layout">
                {this.props.navigation.map((d, i) => generateNav(d, i))}
              </div>
              <div className="navs lt-sm">
                <DropdownMenu
                  id="textfield-dropdown-menu"
                  menuItems={this.props.navigation.map((d, i) => generateNav(d, i))}
                  anchor={{
                    x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
                    y: DropdownMenu.VerticalAnchors.BOTTOM,
                  }}
                  position={DropdownMenu.Positions.BELOW}
                >
                  <AccessibleFakeButton>
                    <div className="mpk-layout align-center">
                      <div className="mpk-padding-M right mpk-hide-xs">Menu</div>
                      <FontIcon>arrow_drop_down</FontIcon>
                    </div>
                  </AccessibleFakeButton>
                </DropdownMenu>
              </div>
            </div>
          ): (null)}
        </div>
        <div className="rb mpk-layout align-center">
          { auth.isLoggedIn && auth.user ? (
            <AppbarProfile
              auth={this.props.auth}
              actions={this.props.actions}
              notification={this.props.notification}
              menus={this.props.profileMenu}
              appsShortcut={this.props.appsShortcut || this.props.global.appUrl}
              consoleShortcut={this.props.consoleShortcut}
              onLogout={onLogout}
              global={this.props.global}
              appName={appName}
              fixedVersion={this.props.fixedVersion}
              version={this.props.version}
              rightComponent={this.props.rightComponent}
            />
          ) : (<CircularProgress id="appbar-loading-user" scale={.72}/>)}
        </div>
      </div>
    )
  }
}

Appbar.defaultProps = {
  fixedVersion: true,
}

Appbar.propTypes = {
  logo: PropTypes.any,
  actions:PropTypes.element,
  notification:PropTypes.element,
  profileMenu:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.string.isRequired,
      materialIcon:PropTypes.string.isRequired,
      onClick:PropTypes.func.isRequired
    })
  ),
  navigation:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.string.isRequired,
      path:PropTypes.string.isRequired
    })
  ),
  translate:PropTypes.bool,
  onLogout:PropTypes.func,
  appName:PropTypes.string,
  onLogoClick:PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  global:state.global
});

const mapDispatchToPros = dispatch => ({
  globalActions:bindActionCreators(globalActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToPros)(Appbar);