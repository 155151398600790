/**
 * Created by dwiargo on 12/4/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectionControl } from 'react-md';
import counterpart from 'counterpart';

class TableColumn extends Component{
  onChange = (col, val) => {
    this.props.tableActions.updateColumn(col, {show:val})
  };

  render(){
    return (
      <div 
        className="mpk-content mpk-padding-N all mpk-full height width" 
        style={{
          position: 'absolute'
        }}
      >
        <div className="mpk-font-color-D3 mpk-font-size-S mpk-padding-S bottom">
          {counterpart.translate('sentence.table.setColumnInfo')}
        </div>
        { this.props.columns.map((col, k) => {
          return col.type === "_actions" ? (null) : (
            <SelectionControl
              key={k}
              id={"switch-"+col.label}
              label={this.props.translate ? counterpart.translate(col.label) : col.label}
              name="power"
              type="switch"
              checked={col.show}
              onChange={(val) => this.onChange(col, val)}
            />
          )
        })}
      </div>
    )
  }
}

TableColumn.propTypes = {
  tableActions:PropTypes.object.isRequired,
  columns:PropTypes.array.isRequired
}

export default TableColumn;