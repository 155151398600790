import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

const path = '/application';
export const get = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const getById = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`)
  return httpService.get(options);
}

export const post = (data) => {
  let options = utilsService.generateGatewayOptions(`${path}`)
  options.data = data;
  return httpService.post(options);
}

export const put = (id, data) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`)
  options.data = data;
  return httpService.put(options);
}

export const remove = item => {
  let options = utilsService.generateGatewayOptions(`${path}/${item.id}`)
  return httpService.delete(options);
}

export const regenerate = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/regenerate`)
  return httpService.get(options);
}