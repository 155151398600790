import React from 'react';
import { FontIcon, Button, DialogContainer } from 'react-md';
import t from 'counterpart';
import './Hint.scss';

class Hint extends React.Component{
	constructor(){
		super();
		this.state = {
			showMore: false
		}
	}
	render(){
		let { className = "", showIcon, text, children, iconClassName="mdi mdi-information", more, inCenterContainer=false, ...otherProps } = this.props;
		return(
			<div className={`mpk-hint ${className}`} {...otherProps}>
				<div className={`mpk-layout align-center content ${inCenterContainer ? 'mpk-center-container' : ''}`}>
					{ showIcon ? <FontIcon className="flex-none icon" iconClassName={iconClassName} /> : null }
					{ children ? children : (
						<div className="mpk-body-text flex">{text}</div>
					)}
					<div className="actions">
						{more ? 
							<Button 
								icon secondary iconClassName="mdi mdi-arrow-top-right"
								onClick={() => this.setState({showMore: true})}
							/> 
						: null}
					</div>
				</div>
				<DialogContainer
					id="mpk-hint-more-dialog"
					title="Hint"
					focusOnMount={false}
					visible={this.state.showMore}
					onHide={() => this.setState({showMore: false})}
					actions={[
						<Button 
							flat primary 
							onClick={() => this.setState({showMore: false})}
						>
							{t.translate('word.close')}
						</Button>
					]}
				>
					{more}
				</DialogContainer>
			</div>
		)
	}
}

Hint.defaultProps = {
	showIcon: true
}

export default Hint;