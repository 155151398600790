import React from 'react';
import authService from '../../services/authService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as globalActions from '../../redux/actions/globalActions';

class SidebarBalance extends React.Component{
  state = {
    isLoading: true,
    available: false,
    widget: null
  }

  componentDidMount(){
    this.checkingInterface();
    window.addEventListener('mpk-update-access-token', e => {
      let { access_token } = e.detail;
      let { widget } = this.state;
      if(widget) widget.updateAccessToken(access_token);
    })    
  }

  componentWillUnmount(){
    window.removeEventListener('mpk-update-access-token', () => {}, false);
  }

  checkingInterface = () => {
    let count = 0;
    let tick = setInterval(() => {
      count++;
      if(count > 3) clearInterval(tick);
      else{
        if(window.userBalance){
          clearInterval(tick);
          this.init();
        } else {
          count++
        }
      }
    }, 1000)
  }

  init = () => {
    this.setState({
      available: true,
      isLoading: false
    }, () => {
      window.userBalance.setOnMessageCallback((message) => {
        let { type } = message;
        switch(type){
          case 'on-show-details-ub':
            this.props.globalActions.setProperties({userBalanceVisible: true});
            break;
          case 'on-get-new-access-token':
            this.props.auth.getNewAccessToken();
            break;
          default:
          return;
        }
      })
      console.log(authService.getAccessToken())
      window.userBalance.render('user-balance-id', {
        widgetClientId: this.props.global.widgetInterface.userBalance.clientId,
        accessToken: authService.getAccessToken()
      }, true, widget => {
        this.setState({widget})
      })
    })
  }

  render(){
    let { available } = this.state;
    return available && (
      <div>
        <div id="user-balance-id"/>
      </div>
    ) 
  }
}

const mapStateToProps = state => ({
  global:state.global,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  globalActions:bindActionCreators(globalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarBalance);
