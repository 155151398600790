/**
 * Created by dwiargo on 12/5/17.
 */

import * as types from './actionTypes';

export const setProperties = (properties) => {
  return {
    type:types.GLOBAL_SET_PROPERTIES,
    properties
  }
};

export const toggleProperty = (key) => {
  return {
    type:types.GLOBAL_TOGGLE_PROPERTY,
    key
  }
}