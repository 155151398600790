import React from 'react';
import { merge } from 'lodash';
import * as service from '../../service';
import { httpService, rdxConnect, SearchField, Pagination, LoaderInfo, NotFoundData, numberService } from 'libs/react-mpk';
import t from 'counterpart';
import { Button, DataTable, TableBody, TableHeader, TableColumn, TableRow } from 'react-md';
import moment from 'moment';
import Form from './Form';
import { utilsService } from 'services';

class Refund extends React.Component{
  constructor(){
    super();
    this.state = {
      data: [],
      isLoading: false,
      showForm: false,
      selectedItem: null,
      query: {
        page: 1,
        size: 20,
        keyword: '',
        column: '',
        sort: 'DESC',
        sortBy: 'transactionDateTime',
        total: 0
      }
    }
  }

  componentDidMount(){
    this.fetchData();
  }

  fetchData = async (newQuery = {}) => {
    let { query } = this.state;
    query = merge(query, newQuery);
    query.total = 0;
    this.setState({isLoading: true, query})
    try {
      let data = [];
      let res = await service.getRefund(query, item => {
        data.push(item);
        this.setState({data})
      });

      query.total = Number(res.headers['x-pagination-count']);
      this.setState({query, isLoading: false});
    } catch (error) {
      this.props.toastActions.izi(
        t.translate('word.failed'),
        httpService.utils.parseErrorMessage(error, this.props.global.localeCode),
        'warning'
      )
    }
  }

  updateData = item => {
    let { data } = this.state;
    data = utilsService.updateDataItemInArray(data, {id: item.id}, item);
    this.setState({data});
  }

  render(){
    let { query, isLoading, data } = this.state;
    let { userBalance } = this.props.tmpObject;
    let columns = [
      {
        label: '#',
        value: (d, i) => (
          (i+1)+(query.size*(query.page-1))
        )
      },
      {
        label: t.translate('column.debitDateTime'),
        value: (d) => (moment(d.debitDateTime).format('lll'))
      },
      {
        label: t.translate('column.status'),
        value: (d) => {
          return (
            <div className="mpk-padding-S padding-top padding-bottom">
              <div className={`mpk-status ${d.statusMessage.toLowerCase().match('success') ? 'progress' : 'idle outline'} mpk-full width mpk-align-center`}>
                {d.statusMessage}
              </div>
            </div>
          )
        }
      },
      {
        label: t.translate('column.amount'),
        value: (d) => (numberService.parseNumber(d.amount))
      },
      {
        label: t.translate('word.actions'),
        value: (d) => (
          <Button 
            iconClassName="mdi mdi-eye" icon
            onClick={() => this.setState({showForm: true, selectedItem: d})}
          />
        )
      },
    ]

    return(
      <div className="mpk-layout column mpk-full viewport-width height mpk-animation slide-in">
        <div 
          className="mpk-border thin solid border-bottom dark mpk-padding-N padding-all mpk-layout align-center justify-between"
          style={{
            height: 48
          }}
        >
          <div className="mpk-layout align-center mpk-position">
            <SearchField 
              withBorder={false}
              onSubmit={keyword => this.fetchData({keyword, page: 1})}
            />
            <Pagination 
              {...query}
              onChange={ page => this.fetchData({page})}
            />
          </div>
          <div className="mpk-layout align-center">
            <Button 
              icon iconClassName="mdi mdi-reload"
              primary onClick={() => this.fetchData()}
              mini style={{width: 40, height: 40}}
              className="mpk-margin-S margin-right"
            />
            <Button
              primary raised
              iconClassName="mdi mdi-plus"
              className="mpk-margin-NONE margin-all"
              onClick={() => this.setState({showForm: true, selectedItem: null})}
              mini style={{width: 56, height: 40}}
              disabled={userBalance === null || userBalance.currentBalance === 0}
            />
          </div>
        </div>
        { isLoading ? <LoaderInfo/> : (
          query.total === 0 ? <div className="mpk-padding-M padding-top padding-bottom"><NotFoundData/></div> : (
            <DataTable
              className="height"
              baseId="ub-refund-table"
              selectableRows={false}
            >
              <TableHeader>
                <TableRow>
                  {columns.map(col => (
                    <TableColumn
                      key={`header-transaction-list-${col.label}`}
                    >
                      {col.label}
                    </TableColumn>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((d,i) => (
                  <TableRow key={`body-transaction-row-${i}`}>
                    {columns.map(col => (
                      <TableColumn key={`body-transaction-row-${i}-col-${col.label}`}>
                        {col.value(d, i)}
                      </TableColumn>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </DataTable>
          )
        )}
        <Form
          visible={this.state.showForm}
          onCancel={() => this.setState({showForm: false, selectedItem: null})}
          item={this.state.selectedItem}
          onSuccess={this.updateData}
        />
      </div>
    )
  }
}

export default rdxConnect(Refund);