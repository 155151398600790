import React from 'react';
import { Router } from 'react-router-dom';
import routes from './routes';

const Setting = (props) => (
  <Router history={props.router.history}>
    {routes()}
  </Router>
)

export default Setting;