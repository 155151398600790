import React from 'react';
import { Router } from 'react-router-dom';
import { ssoConnect, rdxConnect, navService } from 'libs/react-mpk';
import './App.scss';
import { hosts } from 'services';
import createHistory from 'history/createBrowserHistory';
import SupportMpk from 'libs/support-mpk';
import Script from 'react-load-script';
import routes from './routes';

const history = createHistory();

class App extends React.Component{
  componentWillMount(){
    navService.init(history, this.props.globalActions);
  }

  render(){
    let { widgetInterface, kbs, appInfo } = this.props.global;
    return (
      <div className="App mpk-full viewport-height">
        { widgetInterface && widgetInterface.userBalance ? <Script url={widgetInterface.userBalance.url}/> : null }
        <Router history={history} className="mpk-position relative">
          {routes(this.props)}
        </Router>
        { kbs && kbs.length > 0 ? (
          <SupportMpk 
            appName={appInfo.name}
            kbs={kbs}
          />
        ) : null}
      </div>
    );
  }
}

export default ssoConnect({
  exchangeToken:'/api/iams/exchangeToken',
  refreshToken:'/api/iams/refreshToken/:refreshToken',
  login:'/api/iams/login',
  me:'/api/iams/me',
}, 
  () => { return hosts.getAll().iam.host }, 
  () => { return hosts.getAll().iam.credentials }, 
  history,
  null, false
)(rdxConnect(App));
