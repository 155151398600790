import React from 'react';
import { Router } from 'react-router-dom';
import routes from './routes';
import { rdxConnect, authService } from 'libs/react-mpk';
import * as PIN from './PIN';

const actions = {
  CLOSE_WINDOW:{
    resolver:'close-window-ub'
  },
  READY:{
    initial: 'preparing-ub',
    resolver:'on-ready-ub'
  },
  UPDATE:{
    accessToken: 'update-access-token-ub'
  }
}

class Widget extends React.Component{
  constructor(){
    super();
    this.state = {
      isReadyToPostMessage: false,
      showPin: true,
      isPinSetup: true
    }
  }
  componentWillMount(){
    this.setListener();
  }

  componentWillUnmount(){
    window.removeEventListener('beforeunload', () => {}, false);
    window.removeEventListener('message', () => {}, false);
  }

  setListener = () => {
    window.addEventListener('beforeunload', e => {
      this.postMessage(actions.CLOSE_WINDOW.resolver, null);
    })

    window.addEventListener('message', event => {
      if(event.origin !== window.location.origin) {
        if(!this.state.isReadyToPostMessage){
          this.setState({
            openerOrigin: event.source,
            isReadyToPostMessage: true
          })
        }

        if(typeof event.data === 'object'){
          this.eventHandler(event.data)
        }
      }
      
    }, false)
  }

  eventHandler = action => {
    switch(action.eventName){
      case actions.READY.initial:
        this.postMessage(actions.READY.resolver, null);
        break;
      case actions.UPDATE.accessToken:
        authService.setAccessToken(action.data);
        break;
      default:
        return;
    }
  }

  postMessage = (eventName, data) => {
    let { parentOrigin } = this.props.tmpObject;
    window.parent.postMessage({eventName, data}, parentOrigin);
  }

  render(){
    return(
      <>
        <Router history={this.props.router.history}>
          {routes()}
        </Router>
        <PIN.Input/>
      </>
    )
  }
}

export default rdxConnect(Widget);