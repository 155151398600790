import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

const path = '/user_balance_account';
export const get = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const getById = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`)
  return httpService.get(options);
}

export const getTransaction = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/transaction`)
  return httpService.get(options);
}

export const getRefund = (id, params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/refund`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem);
}

export const getBank = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/bank`)
  return httpService.get(options);
}