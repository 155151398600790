/**
 * Created by dwiargo on 12/14/17.
 */

import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { Paper } from 'react-md';
import './CardArticleHeadline.scss';

class CardArticleHeadline extends Component{
  render(){
    let { title, info, img, className } = this.props;
    return(
      <Paper className={"mpk-card-article-headline "+className}>
        <img src={img} role="presentation" alt="article-headline"/>
        <div className="overlay">
          <div className="title mpk-link-on-hover">{title}</div>
          <div className="info">{info}</div>
        </div>
      </Paper>
    )
  }
}

CardArticleHeadline.propTypes = {
  title:PropTypes.string,
  info:PropTypes.string,
  img:PropTypes.string.isRequired
};

export default CardArticleHeadline;