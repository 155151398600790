import React from 'react';
import { tableWrapper } from 'components';
import { Commandbar, navService } from '../../../../libs/react-mpk';
import { Switch } from 'react-md';
import t from 'counterpart';
import * as service from './service';

const BalanceAccountList = props => {
  return(
    <div className="flex mpk-layout column">
      <Commandbar
        title={t.translate('routes.userBalance.balanceAccount.title')}
        breadcrumbs={[t.translate('routes.userBalance.title')]}
        actions={[
          {
            label:'word.reload',
            iconClassName:'mdi mdi-reload',
            onClick: props.tableActions.reload,
          }
        ]}
        rightCorner={(
          <Switch
            id="switch-table-setting"
            name="Switch Table Setting"
            label=""
            checked={props.state.showFilter}
            onChange={props.handle.toggleFilter}
          />
        )}
        translate={false}
      />
      <div className="mpk-padding-N padding-all flex">
        {props.children}
      </div>
    </div>
  )
}

export default tableWrapper((props, handle) => {
  const editItem = item => {
    navService.redirectTo(`/main/user/balance-account/${item.id}/profile`)
  }

  return {
    hint:{children: t.translate('routes.userBalance.balanceAccount.hint.text')},
    columns:[
      {label: t.translate('column.accountNo'), value: 'accountNo', show: true, isSearchable: true, isSortable: true, onClick: editItem},
      {label: t.translate('column.username'), value: 'username', show: true, isSearchable: true, isSortable: true},
      {label: t.translate('column.status'), value: 'status', show: true, type: 'boolean'}
    ],
    fetchData: (params, onDataItem) => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await service.get(params, onDataItem);
          resolve({data: [], total: Number(res.headers['x-pagination-count'])});
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
    tableProps:{
      selectableRows: false
    }
  }
})(BalanceAccountList)