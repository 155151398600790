import React from 'react';
import { tableWrapper } from 'components';
import t from 'counterpart';
import * as service from './service';
import * as balanceAccountService from '../BalanceAccount/service';
import Details from './TransactionDetails';
import { updateParams } from '../../../../libs/react-mpk/redux/actions/tableActions';

const TransactionList = (props) => {
  return(
    <div className="mpk-full height mpk-padding-N padding-all">
      {props.children}
      <Details 
        visible={props.state.showForm}
        item={props.state.selectedItem}
        onCancel={() => props.handle.showForm(false)}
      />
    </div>
  )
}

export default tableWrapper((props, handle) => {
  let { userBalanceAccount } = props.tmpObject;
  return {
    hint:{
      children: userBalanceAccount 
        ? t.translate('routes.userBalance.transaction.hint.userText').replace('*name', userBalanceAccount.userInfo.username)
        : t.translate('routes.userBalance.transaction.hint.text')
    },
    columns:[
      {label: t.translate('column.accountNo'), value: 'userBalanceAccount.accountNo', show: true, isSearchable: true, isSortable: true, onClick: item => handle.showForm(true, item)},
      {label: t.translate('column.username'), value: 'userBalanceAccount.username', show: true, isSearchable: true, isSortable: true},
      {label: t.translate('column.dateTime'), value: 'transactionDateTime', type: 'date', show: true, isSearchable: false, isSortable: true},
      {label: t.translate('column.transactionType'), value: 'transactionType.name', show: true, isSearchable: true, isSortable: true},
      {label: 'D/C', value: 'transactionType.debitCredit', show: true, isSearchable: true, isSortable: true},
      {label: t.translate('column.amount'), value: 'amount', type: 'number', show: true, isSearchable: false, isSortable: true},
      {label: t.translate('column.closeBalance'), value: 'closeBalance', type: 'number', show: true, isSearchable: false, isSortable: true},
      {label: t.translate('column.openBalance'), value: 'openBalance', type: 'number', show: false, isSearchable: false, isSortable: true},
    ],
    itemActions: [
      {
        label:"Show",
        iconClassName:"mdi mdi-eye",
        onClick: item => handle.showForm(true, item),
      },
    ],
    fetchData: (params, onDataItem) => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = userBalanceAccount
            ? await balanceAccountService.getTransaction(userBalanceAccount.accountInfo.id, updateParams) 
            : await service.get(params, onDataItem);
          resolve({data: [], total: Number(res.headers['x-pagination-count'])});
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
    tableProps:{
      selectableRows: false
    }
  }
})(TransactionList)