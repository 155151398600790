/**
 * Created by dwiargo on 2/22/18.
 */

const allHosts = {};

const hosts = {
  setHost:(hostsObject) => {
    let keys = Object.keys(hostsObject);
    for(var i = 0 ; i < keys.length ; i++){
      let key = keys[i];
      allHosts[key] = hostsObject[key]
    }
  },
  getAll:() => {
    return allHosts;
  },
  getHost: key => {
    return allHosts[key];
  }
}

export default hosts;