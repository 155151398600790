/**
 * Created by dwiargo on 2/7/18.
 */

import React, { Component } from 'react';
import logoDJP from '../../assets/logo-djp.png';
import t from 'counterpart';
import env from '../../config/env';

import './MitraDJP.scss';
import { DialogContainer, Button } from 'react-md';

class MitraDJP extends Component{
  constructor(){
    super();
    this.state = {
      showDetails: false
    }
  }

  render(){
    return(
      <div 
        className={"mpk-mitra-djp mpk-layout align-center mpk-font-color-D1 "+(this.props.light ? 'light' : 'default')}
        style={{
          maxWidth: 80,
          background: this.props.isAlpha ? 'rgba(0,0,0,0)' : 'white'
        }}
      >
        <img src={logoDJP} alt="logo-djp" role="presentation"/>
        <div 
          className="body mpk-padding-S padding-left mpk-margin-S margin-left"
          onClick={() => this.setState({showDetails: true})}
        >
          <div className="mpk-font-size-S mpk-font-color-D2">Mitra</div>
          <div className="mpk-font-weight-B djp mpk-link">djp</div>
        </div>
        <DialogContainer
          title="Mitra Resmi"
          id="mpk-mitra-djp-details"
          visible={this.state.showDetails}
          onHide={() => this.setState({showDetails: false})}
          actions={[
            <Button
              flat
              onClick={() => this.setState({showDetails: false})}
            >
              {t.translate('word.close')}
            </Button>
          ]}
        >
          <div style={{textAlign: 'center'}}>
            { env.theme === env.themes.PAJAKKU ? (
              <img src="https://www.pajakku.com/static/assets/pjap-resmi.jpeg" alt="pjap-resmi"/>
            ) : (
              <img src="https://www.sobatpajak.com/static/assets/pjap-resmi.jpeg" alt="pjap-resmi"/>
            )}
          </div>
        </DialogContainer>
      </div>
    )
  }
}

MitraDJP.defaultProps = {
  isAlpha:false
}

export default MitraDJP;