import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as globalActions from '../redux/actions/globalActions';
import * as authActions from '../redux/actions/authActions';
import * as toastActions from '../redux/actions/toastActions';
import * as tableActions from '../redux/actions/tableActions';
import * as dialogActions from '../redux/actions/dialogActions';
import * as tmpObjectActions from '../redux/actions/tmpObjectActions';

export default (WrappedComponent) => {
    return connect( state => ({
      auth:state.auth,
      table: state.table,
      global: state.global,
      tmpObject: state.tmpObject
    }), dispatch => ({
      authActions: bindActionCreators(authActions, dispatch),
      dialogActions: bindActionCreators(dialogActions, dispatch),
      globalActions: bindActionCreators(globalActions, dispatch),
      tableActions: bindActionCreators(tableActions, dispatch),
      toastActions: bindActionCreators(toastActions, dispatch),
      tmpObjectActions: bindActionCreators(tmpObjectActions, dispatch)
    }))( props => {
      return <WrappedComponent {...props} />;
    })
}