/**
 * Created by dwiargo on 12/14/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import renderHtml from 'react-render-html';
import './Address.scss';

const address = [
  {
    header:"Operational Office",
    body:"The Nebula Center 2<sup>nd</sup> Floor</br>Jl. Kemanggisan Utama Raya no. J4 </br>Palmerah, Jakarta 11480",
    contact:[
      {
        label:"Phone Hunting",
        value:"62 21 2253 9393"
      },
      {
        label:"Marketing Mobile",
        value:"0819 3418 7982"
      },
      {
        label:"Pemasaran",
        value:"marketing@pajakku.com"
      }
    ]
  },
  {
    header:"Bandung",
    body:"The Nebula Center <br>Jl. Cigadung Raya Barat no.6<br>Bandung, Jawa Barat 40191",
    contact:[]
  },
  {
    header:"Bali",
    body:"Jl. Tukad Unda IV no. 3X, Renon<br>Denpasar, Bali 80226",
    contact:[]
  }
]

class Address extends Component{
  render(){
    let { light } = this.props;

    const contact = (items) => {
      return items.map((item, i) => (
        <div className="contact" key={i}>
          <div className="label">{item.label}</div>
          <div className="value body-text">{item.value}</div>
        </div>
      ))
    };

    return(
      <div className={"mpk-address mpk-layout column row-sm justify-between column-xs "+ (light ? 'light' : '')}>
        { address.map((d, i) => (
          <div className="group" key={i}>
            <div className="header">{d.header}</div>
            <div className="body body-text">
              {renderHtml(d.body)}
            </div>
            {contact(d.contact)}
          </div>
        ))}
      </div>
    )
  }
}

Address.propTypes = {
  light:PropTypes.bool
};

export default Address;