/**
 * Created by dwiargo on 3/7/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Snackbar } from 'react-md';

import * as toastActions from '../../redux/actions/toastActions';

class Toast extends Component{
  render(){
    return(
      <Snackbar
        id="mpk-snackbar"
        toasts={this.props.toast.toasts}
        onDismiss={this.props.toastActions.dismiss}
        />
    )
  }
}

const mapStateToProps = state => ({
  toast:state.toast
});

const mapDispatchToProps = dispatch => ({
  toastActions:bindActionCreators(toastActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Toast);