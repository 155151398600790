import React from 'react';
import t from 'counterpart';
import detailsWrapper from '../../../../../components/detailsWrapper';
import { Commandbar, httpService, navService } from '../../../../../libs/react-mpk';
import { Button } from 'react-md';
import * as service from '../service';

import Profile from './Profile';
import Member from './Member';

class UserGroupDetails extends React.Component{
  componentWillUnmount(){
    this.props.tmpObjectActions.setProperties({userGroup: null});
  }

  handleBack = () => navService.redirectTo('/main/setting/user-group-list');

  render(){
    let { userGroup } = this.props.tmpObject;
    return(
      <div className="flex mpk-layout column">
        <Commandbar
          title={userGroup ? `${userGroup.name}` : '...'}
          breadcrumbs={[
            t.translate('routes.setting.title'),
            t.translate('routes.setting.userGroup.title')
          ]}
          leftCorner={(
            <Button
              icon iconClassName="mdi mdi-arrow-left"
              onClick={this.handleBack}
              />
          )}
          rightCorner={ this.props.auth.hasPermission(`DELETE:/user_group/{id}`) && (
            <Button
              iconClassName="mdi mdi-delete"
              raised primary
              onClick={() => {
                this.props.dialogActions.showConfirm(
                  t.translate('sentence.dialog.delete.title'),
                  t.translate('sentence.dialog.delete.message'),
                  async (callback) => {
                    try {
                      await service.remove(userGroup.id);
                      this.handleBack();
                      callback(false, t.translate('sentence.info.deletedItem'));
                    } catch (error) {
                      callback(true, httpService.utils.parseErrorMessage(error))
                    }
                  }
                )
              }}
            >
              {t.translate('word.delete')}
            </Button>
          )}
        />
        {this.props.children}
      </div>
    )
  }
}

export default detailsWrapper((props) => ({
  tabs:[
    {
      label: t.translate('column.profile'),
      key: 'profile',
      component: <Profile/>,
    },
    {
      label: t.translate('column.member'),
      key: 'member',
      component: <Member/>,
      resourceUri: 'GET:/user_group/{id}/member'
    }
  ],
  onTabChange: (tab) => {
    props.router.history.push(`/main/setting/user-group/${props.router.match.params.id}/${tab.key}`)
  },
  onInit: async (callback) => {
    try {
      let res = await service.getById(props.router.match.params.id);
      props.tmpObjectActions.setProperties({userGroup: res.data});
      callback();
    } catch (error) {
      props.toastActions.izi(httpService.utils.parseErrorMessage(error));
      navService.redirectTo(`/main/setting/user-group-list`)
    }
  }
}))(UserGroupDetails);