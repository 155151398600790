/**
 * Created by dwiargo on 12/5/17.
 */

import React, { Component } from 'react';
import NotificationItem from './NotificationItem';
import { Button, FontIcon } from 'react-md';
import './Notification.scss';
import { DialogContainer } from '../Dialog';
import t from 'counterpart';

class Notification extends Component{
  constructor(){
    super();
    this.state = {
      showDetails: false
    }
  }

  showDetails = () => {
    this.setState({showDetails: true});
    this.props.onClick();
  }
  
  render(){
    let { newFeeds, dataItems, children } = this.props;
    return(
      <div className="mpk-notification">
        <div 
          className={"badge "+ (newFeeds > 0 ? "active" : "")} 
          onClick={this.showDetails}
        >
          <FontIcon iconClassName="mdi mdi-bell"/>
          <div class="number">{newFeeds > 99 ? '+99' : newFeeds}</div>
        </div>
        <DialogContainer
          id="mpk-notification"
          title={t.translate('word.notification')}
          visible={this.state.showDetails}
          onHide={() => this.setState({showDetails: false})}
          focusOnMount={false}
          actions={[
            <Button 
              flat
              onClick={() => this.setState({showDetails: false})}
            >
              {t.translate('word.close')}
            </Button>
          ]}
          dialogStyle={{
            width: '100%',
            maxWidth: 480
          }}
        >
          {children ? children : dataItems}
        </DialogContainer>
      </div>
    )
  }
}

Notification.Item = NotificationItem;
Notification.defaultProps = {
  dataItems: [],
  newFeeds: 0,
  onClick: () => {}
}

export default Notification;