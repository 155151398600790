import React from 'react';
import { Button } from 'react-md';
import t from 'counterpart';
import './Step.scss';
import StatusInfo from '../StatusInfo';

const StepItem = ({index, item, active=false}) => (
  <div className={`mpk-step-item mpk-layout align-center ${active ? 'active' : ''}`}>
    <div className="number">
      {index + 1}
    </div>
    <div className="label">
      <div>
        <div>{item.label}</div>
        {item.description ? (
          <div className="mpk-font-size-S mpk-font-color-D3">{item.description}</div>
        ) : null}
      </div>
    </div>
  </div>
)

const StepBar = ({steps, currentIndex}) => (
  <div className="mpk-step-bar mpk-layout align-center column-xs">
    {steps.map((item, index) => (
      <StepItem 
        {...{item, index}} 
        key={`mpk-step-item-${index}`}
        active={currentIndex === index}
      />
    ))}
  </div>
)

class StepContent extends React.Component{
  constructor(){
    super();
    this.state = {
      animationClass:'',
      onProgress: false
    }
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.currentIndex !== this.props.currentIndex){
      this.setState({animationClass: nextProps.currentIndex > this.props.currentIndex ? 'slide-right' : 'slide-left'})
      setTimeout(() => this.setState({animationClass: ''}), 700);
    } 
  }

  onSubmit = () => {
    this.setState({onProgress: true}, () => {
       this.props.onSubmit(() => {
        this.setState({onProgress: false})
      })
    })
  }

  render(){
    let {currentIndex, steps, onStepChange, disabled} = this.props
    let {onProgress} = this.state;
    return(
      <div className={`mpk-step-content mpk-animation ${this.state.animationClass}`}>
        {steps[currentIndex].component}
        <div className="actions mpk-layout align-center justify-right">
          { currentIndex > 0 ? (
            <Button 
              flat className="mpk-margin-NS right"
              onClick={() => onStepChange(true)}
              disabled={onProgress}
            >
              {t.translate('word.previous')}
            </Button>
          ) : null }
          {currentIndex < steps.length - 1 ? (
            <Button 
              flat className="mpk-margin-NS right"
              onClick={() => onStepChange()}
              disabled={onProgress}
            >
              {t.translate('word.next')}
            </Button>
          ) : (
            <Button 
              raised primary className="mpk-margin-NS right"
              onClick={() => onStepChange()}
              disabled={disabled || onProgress}
            >
              {t.translate('word.submit')}
            </Button>
          )}
          
        </div>
      </div>
    )
  }
} 

class Step extends React.Component{
  constructor(){
    super();
    this.state = {
      currentIndex: 0
    }
  }

  // componentWillReceiveProps(nextProps){
  //   if(nextProps.initialIndex && nextProps.initialIndex !== this.state.currentIndex){
  //     // this.setState({currentIndex: nextProps.initialIndex});
  //   }
  // }

  componentDidMount(){
    console.log(this.props);
  }

  onStepChange = (isPrev) => {
    let { currentIndex } = this.state;
    let { steps } = this.props;
    let currentStep = steps[currentIndex];
    let next = () => {
      if(isPrev){
        if(currentIndex > 0){
          this.setState({currentIndex: currentIndex - 1});
        }
      } else {
        if(currentIndex < steps.length - 1){
          this.setState({currentIndex: currentIndex + 1});
        } else {
          this.props.onSubmit( index => {
            setTimeout(() => {
              this.setState({currentIndex: index})
            }, 500);
          });
        }
      }
      // this.props.onStepChange(currentIndex);
    }

    if(currentStep.onBeforeStepChange && !isPrev){
      currentStep.onBeforeStepChange(next)
    }else{
      next();
    }
  }

  render(){
    let { className, steps, onSubmit, isError, errorMessage, disabled} = this.props;
    let { currentIndex } = this.state;
    return (
      <div
        className={`mpk-step mpk-full width ${className}`}
      >
        <StepBar {...{steps, currentIndex}}/>
        { isError ? (
          <StatusInfo
            status="error"
            message={errorMessage}
            className="mpk-margin-NS top bottom"
          />
        ) : null}
        <StepContent {...{
          steps, currentIndex, 
          onStepChange: this.onStepChange,
          disabled, onSubmit
        }}/>
      </div>
    )
  }
}

Step.defaultProps = {
  className:'',
  onSubmit: callback => {callback()},
  onStepChange: step => {console.log(`react-mpk step at ${step}`)},
  isError: false,
  disabled: false,
  errorMessage: 'Error message is not define',
  steps:[
    {
      label: 'Label for Step 1',
      description: 'description for step 1',
      component: <div>Step 1</div>,
      onBeforeStepChange: (next) => {
        next();
      }

    },
    {
      label: 'Label for Step 2',
      description: 'description for step 2',
      component: <div>Step 2</div>
    },
    {
      label: 'Done',
      component: <div>Done</div>
    }
  ]
}

export default Step;