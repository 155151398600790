/**
 * Created by dwiargo on 12/4/17.
 */

import React, { Component } from 'react';
import { TextField, Button, SelectField, DatePicker } from 'react-md';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import Empty from '../Empty';
import moment from 'moment';

const today = new Date();
const initialState = () => ({
  defaultColumn:'',
  keyword: '',
  column: '',
  startDate:'',
  endDate:''
});

class TableSearch extends Component {
  constructor() {
    super();
    this.state = initialState();
  }

  componentWillMount() {
    let { keyword, defaultColumn, column } =  this.props.table.params
    this.setState({column: defaultColumn || column, defaultColumn:defaultColumn, keyword})
  }

  onSubmit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    this.search();
  };

  onReset = () => {
    let { column, defaultColumn } = this.state;
    let newParams = initialState();
        newParams.defaultColumn = column || defaultColumn;
        newParams.column = column || defaultColumn;
    this.setState(newParams);
    this.props.tableActions.setProperties({
      params:newParams
    });
    setTimeout(() => this.search(true));
  };

  search = (isReset=false) => {
    let { startDate, endDate } = this.state;
    let params = {
      page: 1,
      keyword: this.state.keyword,
      column: this.state.column && this.state.column !== '' ? this.state.column : this.props.table.params.column
    };

    params.startDate = startDate;
    params.endDate = endDate;
    
    this.props.onSearch(params, isReset);
  };

  render() {
    let { extraFilter } = this.props;
    let table = this.props.table;
    let _columns = table.columns.filter(col => col.isSearchable).map(d => ({
      label: this.props.translate ? counterpart.translate(d.label) : d.label,
      value: d.valueName || d.value
    }));

    return (
      <form 
        className="mpk-layout column fill" onSubmit={this.onSubmit}
        style={{position: 'absolute'}}
      >
        <div className="mpk-padding-N all flex mpk-content">
          {_columns.length > 0 ? (
            <div>
              <SelectField
                id="mpk-table-search-column"
                label={counterpart.translate('word.column')}
                menuItems={_columns}
                placeholder="Select target column"
                value={this.state.column}
                onChange={(value) => this.setState({column:value})}
                style={{width:'100%'}}
                simplifiedMenu
                />
              <TextField
                id="mpk-table-search-keyword"
                label={counterpart.translate('word.keyword')}
                lineDirection="center"
                placeholder={counterpart.translate('sentence.table.searchKeyword')}
                onChange={(value) => this.setState({keyword: value})}
                value={this.state.keyword}
                required={false}
                />
            </div>
          ) : null }
          { this.props.showPeriods ? (
            <div>
              <div className="mpk-layout align-center">
                <DatePicker
                  id="mpk-table-searct-startDate"
                  label={counterpart.translate('word.startDate')}
                  displayMode="portrait"
                  maxDate={today}
                  fullWidth={true}
                  defaultCalendarDate={this.state.startDate}
                  value={this.state.startDate}
                  onChange={(dateString, dateObject) => this.setState({startDate: moment(dateObject).format('M/D/YYYY')})}
                  autoOk={true}
                />
                <Button
                  icon iconClassName="mdi mdi-close"
                  style={{width: 28, height: 28, top: 4}}
                  onClick={() => this.setState({startDate: '', endDate: ''})}
                />
              </div>
              <div className="mpk-layout align-center">
                <DatePicker
                  id="mpk-table-searct-endDate"
                  label={counterpart.translate('word.endDate')}
                  displayMode="portrait"
                  maxDate={today}
                  minDate={new Date(this.state.startDate) || today}
                  fullWidth={true}
                  onChange={(dateString, dateObject) => this.setState({endDate: moment(dateObject).format('M/D/YYYY')})}
                  defaultCalendarDate={this.state.endDate}
                  value={this.state.endDate}
                  disabled={this.state.startDate.length === 0}
                  autoOk={true}
                />
                <Button
                  icon iconClassName="mdi mdi-close"
                  style={{width: 28, height: 28, top: 4}}
                  onClick={() => this.setState({endDate: ''})}
                />
              </div>
            </div>
          ) : null}
          <Empty height={24}/>
          {extraFilter}
        </div>
        <div className="mpk-padding-NS all mpk-layout mpk-border dark solid top">
          <Button
            className="flex"
            raised secondary swapTheming 
            disabled={table.isLoading}
            onClick={this.onReset}
            >
            <Translate content="word.reset"/>
          </Button>
          <Empty width={4}/>
          <Button
            className="flex"
            raised secondary swapTheming type="submit"
            disabled={table.isLoading}
          >
            <Translate content="word.search"/>
          </Button>
        </div>
      </form>
    )
  }
}

TableSearch.defaultProps = {
  showPeriods:true,
  extraFilter: null,
}

export default TableSearch;