import React from 'react';
import { DialogContainer } from '../Dialog';
import { Button } from 'react-md';
import t from 'counterpart';
import { connect } from 'react-redux';
import authService from '../../services/authService';

class UserBalance extends React.Component{
  state = {
    isLoading: true,
    available: false,
    widget: null
  }

  componentWillReceiveProps(nextProps){
    let { asDialog } = this.props;
    if(asDialog && nextProps.visible && !this.props.visible){
      this.run();
    }
  }

  componentDidMount(){
    this.run();
     window.addEventListener('mpk-update-access-token', e => {
        let data = e.detail;
        let { widget } = this.state;
        if(widget) widget.updateAccessToken(data.access_token);
      })  
  }

  componentWillUnmount(){
    if(this.thick) clearInterval(this.tick);
    window.removeEventListener('mpk-update-access-token', () => {}, false);
  }

  run = () => {
    let { available } = this.state;
    if(available){
      this.init();
    } else {
      let count = 0;
      this.tick = setInterval(() => {
        count++;
        if(count > 6) clearInterval(this.tick);
        else{
          if(window.userBalance){
            clearInterval(this.tick);
            this.init();
          } else {
            count++
          }
        }
      }, 1000)
    }
  }

  init = () => {
    this.setState({
      available: true,
      isLoading: false
    }, () => {
      setTimeout(() => {
        window.userBalance.render('user-balance-details-id', {
          widgetClientId: this.props.global.widgetInterface.userBalance.clientId,
          accessToken: authService.getAccessToken()
        }, false, widget => this.setState({widget}))
      }, 500)
    })
  }


  render(){
    let { asDialog, visible, onCancel, isLoading } = this.props;
    const Content = (
      <div id="user-balance-details-id"/>
    )
    return asDialog ? (
      <DialogContainer
        className="mpk-dialog no-padding-content no-overflow"
        visible={visible}
        onCancel={onCancel}
        focusOnMount={false}
        onProgress={isLoading}
        actions={[
          <Button
            flat
            iconClassName="mdi mdi-close"
            onClick={onCancel}
          >
            {t.translate('word.close')}
          </Button>
        ]}
        dialogStyle={{
          width: '100%',
          maxWidth: 720
        }}
      >
        {Content}
      </DialogContainer>
    ) : (Content)
  }
}

UserBalance.defaultProps = {
  asDialog: true
}

export default connect(state => ({global: state.global}))(UserBalance);