import React, { Component } from 'react';
import { FontIcon, Button } from 'react-md';
import navService from '../../services/navService';

export default class SidebarMenuGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    }
  }

  componentDidMount(){
    if(this.props.collapsible) {
        this.setState({
          open:this.props.menuData.defaultCollapse
        })
    }
  }

  toggleMenu() {
    if(this.props.collapsible) {
      this.setState((s)=> {
        return {
          open: !s.open
        }
      })
    }
  }

  renderMenuItem(childData) {
    let {getValueByLocaleCode, global, tableActions} = this.props;
    return childData.map((d, i) => {
      let currPath = global.currentPath;
      let isActive = currPath === d.path ? 'active' : (d.childPath && d.childPath.length > 0 && currPath.match(new RegExp(d.childPath.toString().replace(/,/g, '|'), 'ig')) !== null ? 'active' : '');

      return (
        <Button
          flat className={`menu-item mpk-padding-N left right ${isActive}`}
          disabled={isActive === 'active' ? true : false}
          key={i}
          onClick={() => {
            tableActions.resetAll();
            if(d.onClick){
              d.onClick()
            } else {
              if(d.path && !isActive) navService.redirectTo(d.path);
            }
          }}
        >
          <FontIcon iconClassName={d.iconClassName}/>
          <div>{getValueByLocaleCode ? d.label[global.localeCode] : d.label}</div>
        </Button>
      )
    })
  };

  toggleMenuSingle(menuData) {
    if(this.props.collapsible) {
      this.setState((s)=> {
        return {
          open: !s.open
        }
      })
    }
    navService.redirectTo(menuData.path);
  }

  render() {
    let {getValueByLocaleCode, global, menuData, nextData } = this.props;
    var collapsibleClass = '';
    var collapseIcon = 'keyboard_arrow_right';
    if(this.props.collapsible) collapsibleClass = 'collapsible';
    if(this.state.open) collapseIcon = 'keyboard_arrow_down'

    let haveChildMenu = false
    let haveChildren = [null, undefined].indexOf(menuData.children) >= 0 ? false : true;
    if(menuData.children){
      if(menuData.children.length > 0){
        haveChildMenu = true
      }
    }
    
    return haveChildren ? (
      haveChildMenu ? (
        <div className={"group mpk-no-copy "+collapsibleClass}>
          <div className="subheader mpk-padding-N left right" onClick={()=> this.toggleMenu()}>
            {getValueByLocaleCode ? menuData.label[global.localeCode] : menuData.label}
            <FontIcon className="collapse-icon">{collapseIcon}</FontIcon>
          </div>
          {this.state.open &&
            <div className={"menus mpk-animation slide-in-down "+this.props.menuClass} >{this.renderMenuItem(menuData.children)}</div>
          }
        </div>
      ) : (
        <div className={"group "+collapsibleClass}>
          <div className="subheader mpk-padding-N left right" onClick={()=> this.toggleMenuSingle(menuData)}>
            {getValueByLocaleCode ? menuData.label[global.localeCode] : menuData.label}
          </div>
        </div>
      )
    ) : (
      nextData && nextData.children && nextData.children.length > 0 ? (
        <div className="group-divider mpk-font-size-NS">{getValueByLocaleCode ? menuData.label[global.localeCode] : menuData.label}</div>
      ) : null
    )
    // if(haveChildMenu){
    //   return <div className={"group mpk-no-copy "+collapsibleClass}>
    //     <div className="subheader mpk-padding-N left right" onClick={()=> this.toggleMenu()}>
    //       {getValueByLocaleCode ? menuData.label[global.localeCode] : menuData.label}
    //       <FontIcon className="collapse-icon">{collapseIcon}</FontIcon>
    //     </div>
    //     {this.state.open &&
    //       <div className={"menus mpk-animation slide-in-down "+this.props.menuClass} >{this.renderMenuItem(menuData.children)}</div>
    //     }
    //   </div>
    // } else {
    //   return <div className={"group "+collapsibleClass}>
    //     <div className="subheader mpk-padding-N left right" onClick={()=> this.toggleMenuSingle(menuData)}>
    //       {getValueByLocaleCode ? menuData.label[global.localeCode] : menuData.label}
    //     </div>
    //   </div>
    // }
  }
}
