import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, CircularProgress } from 'react-md';

class ExpansionForm extends Component{
    render(){
        let {label, unsaved, onProgress, onSave, className, defaultExpanded, footer, style, ...otherProps} = this.props;
        let labelStatus = () => {
            if(onProgress){
                return (
                    <CircularProgress 
                        scale={.5} 
                        id={`mpk-expansion-form-${label}`}
                    />
            )} else if(unsaved) {
                return (
                    <div className="mpk-font-size-NS mpk-text-info">[unsaved]</div>
            )} else {
                return null
            }
        }

        return(
            <ExpansionPanel
                style={style || {}}
                defaultExpanded={defaultExpanded}
                className={`mpk-expansion-form mpk-full width ${className ? className : ''}`}
                columnWidths={this.props.columnWidths}
                label={(
                    <div className="mpk-layout align-center">
                        <div className="mpk-font-weight-B mpk-font-color-primary mpk-margin-S right">{label}</div>
                        {labelStatus()}
                    </div>
                )}
                footer={footer ? (
                    <div 
                        className="md-dialog-footer mpk-align align-right md-divider-border md-divider-border--top"
                        style={{
                            padding: '10px'
                        }}>
                        {footer}
                    </div>
                ): undefined}
                onSave={onSave}
                closeOnSave={false}
                {...otherProps}
            >
                {this.props.children}
            </ExpansionPanel>
        )
    }
}

ExpansionForm.propTypes = {
    label:PropTypes.any.isRequired,
    onSave:PropTypes.func,
    unsaved:PropTypes.bool,
    onProgress:PropTypes.bool,
    columnWidths:PropTypes.array.isRequired
}

export default ExpansionForm;