/**
 * Created by dwiargo on 12/4/17.
 */

import React, { Component } from 'react';
import {Paper, FontIcon, DialogContainer, Button} from 'react-md';
import './SidebarInfo.scss';
import renderHTML from 'react-render-html';

class SidebarInfo extends Component {
  constructor(){
    super();
    this.state = {
      maximize:false
    }
  }
  render() {
    let {
      title = 'Pemberitahuan',
      message = ''
    } = this.props;

    return (
      <div className="mpk-sidebar-info mpk-padding-N all">
        <Paper className="panel">
          <div className="header mpk-padding-N left right mpk-layout align-center">
            <div className="flex">{title}</div>
            <div className="flex-none">
              <FontIcon
                iconClassName="mdi mdi-open-in-new"
                onClick={() => this.setState({maximize:true})}
              />
            </div>
          </div>
          <div className="body mpk-font-size-NS mpk-padding-N left right bottom">
              {renderHTML(message)}
          </div>
        </Paper>
        <DialogContainer
          id="mpk-sidebar-info-detail"
          className="dialog"
          onHide={()=>{}}
          title={(<div className="title">{title}</div>)}
          visible={this.state.maximize}
          dialogStyle={{
            width: '100%',
            maxWidth: 480
          }}
          actions={[
            <Button flat secondary onClick={() => this.setState({maximize:false})}>
            Ok
          </Button>
          ]}
        >
          <div className="body-text">{renderHTML(message)}</div>
        </DialogContainer>
      </div>
    )
  }
}

export default SidebarInfo;