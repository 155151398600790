import * as types from '../actions/actionTypes';

const initialState = () => ({
    title:'Default Title',
    message: 'Default Message',
    onConfirmed: () => {},
    onCancel:() => {},
    onHide:() => {},
    submitLabel: null,
    cancelLabel: null,
    translate: null,
    showConfirmation: false,
    showAlert:false
})

export default ((state = initialState(), action = {}) => {
    switch(action.type){
        case types.DIALOG_CONFIRM_SHOW:
            return Object.assign({}, state, {
                showConfirmation:true,
                showAlert:false,
                title:action.title,
                message: action.message,
                onConfirmed: action.onConfirmed,
                onCancel:action.onCancel,
                submitLabel:action.submitLabel,
                cancelLabel:action.cancelLabel
            });
        case types.DIALOG_ALERT_SHOW:
            return Object.assign({}, state, {
                showAlert:true,
                title:action.title,
                message: action.message,
                onHide:action.onHide
            });
        
        case types.DIALOG_HIDE:
            return Object.assign({}, state, initialState());
            
        default: 
            return state;
    }
})