import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Wizard.scss';

class Wizard extends Component{
  render(){
    return(
      <div className="mpk-wizard">
        This is wizard
      </div>
    )
  }
}

Wizard.propTypes = {
  steps:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.string.isRequired,
      content:PropTypes.node.isRequired,
      onSubmit:PropTypes.func,
    })
  ).isRequired
}

export default Wizard;