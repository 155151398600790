/**
 * Created by dwiargo on 12/15/17.
 */

import React, { Component } from 'react';
import { Button } from 'react-md';
import Translate from 'react-translate-component';
import './SendEmail.scss';

class SendEmail extends Component{
  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render(){
    return(
      <div className="mpk-send-email flex">
        <div className="header">
          <Translate content={"sentence.generalInfo.header"}/>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="group">
            <label><Translate content={"word.name"}/></label>
            <input
              id="mpk-send-email-name"
              className="body-text"
              required
            />
          </div>
          <div className="group">
            <label><Translate content={"word.email"}/></label>
            <input
              id="mpk-send-email-name"
              className="body-text"
              type="email"
              required
            />
          </div>
          <div className="group">
            <label><Translate content={"word.message"}/></label>
            <textarea
              id="mpk-send-email-name"
              className="body-text"
              required
            />
          </div>
          <Button raised primary>Submit</Button>
        </form>
      </div>
    )
  }
}

export default SendEmail;