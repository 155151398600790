import React, {Component} from 'react';
import ReactNumberFormat from 'react-number-format';

const units = ['byte', 'KB', 'MB', 'GB', 'TB'];

class NumberFormat extends Component{
  constructor(){
    super();
    this.state = {
      value:0,
      unit:''
    }
  }

  componentWillMount(){
    const {from, value} = this.props;
    const divider = 1024;
    const idx = units.indexOf(from) >= 0 ? units.indexOf(from) : 0;
    
    let conv = {
      v:value,
      idx:idx
    };

    for(let i = idx ; i < units.length ; i ++){
      if(conv.v < divider){
        conv.idx = i
        break;
      } else {
        conv.v /= divider
      }
    }

    this.setState({
      value:conv.v,
      unit:units[conv.idx]
    })
  }

  render(){
    return (
      <ReactNumberFormat
        value={this.state.value}
        suffix={` ${this.state.unit}`}
        displayType="text"
        decimalScale={2}
        thousandSeparator={true}
      />
    )
  }
}

NumberFormat.defaultProps = {
  from:units[0],
  value:0
}

export default NumberFormat;