import * as types from './actionTypes';

export const showConfirm = (title='Title is not define', message='Message is not define', onConfirmed=() => {}, onCancel=()=>{}, submitLabel, cancelLabel) => {
    return {
        type: types.DIALOG_CONFIRM_SHOW,
        title, message, onConfirmed, onCancel, submitLabel, cancelLabel
    }
}

export const showAlert = (title='Title is not define', message='Message is not define', onHide=()=>{}) => {
    return {
        type: types.DIALOG_ALERT_SHOW,
        title, message, onHide
    }
}

export const hide = () => {
    return {
        type:types.DIALOG_HIDE
    }
}