/**
 * Created by dwiargo on 3/6/18.
 */

import * as types from './actionTypes';
import iziToast from 'izitoast';

const defaultIziParams = (type) => ({
  title:'',
  message:'NONE',
  position: 'bottomLeft',
  theme:'dark',
  backgroundColor: (() => {
    switch(type){
      case 'warning':
      return '#f44336';
      default:
      return 'rgba(0,0,0,.8)';
    }
  })(),
  progressBarColor: '#888'
})

export const izi = (title, message, type='show') => {
  let params = defaultIziParams(type);
  params.title = title;
  params.message = message;
  iziToast[type](params)

  return {type:'default'}
}

export const add = (text, action, autohide = true) => {
  // return {
  //   type:types.TOAST_ADD,
  //   text, action, autohide
  // }
  console.log(text);
  let params = defaultIziParams();
  params.message = text;
  delete params.title;
  iziToast.show(params)

  return {type:'default'}
};

export const dismiss = () => {
  return {
    type:types.TOAST_DISMISS
  }
}