/**
 * Created by dwiargo on 4/9/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import brace from 'brace';

import 'brace/mode/html';
import 'brace/mode/java';
import 'brace/mode/javascript';
import 'brace/theme/monokai';
import 'brace/theme/github';
import 'brace/theme/chrome';

console.log(brace ? 'brace is defined by script editor' : '');

class ScriptEditor extends Component{
  render(){
    let { mode, onChange, value, theme, className } = this.props;
    return(
      <div className={`mpk-script-editor mpk-full height ${className?className:''}`}>
        <AceEditor
          mode={mode||'javascript'}
          theme={theme||'monokai'}
          onChange={onChange}
          name="mpk-script-editor"
          editorProps={{$blockScrolling: true}}
          value={value||''}
          style={{width: '100%', height: '100%'}}
          />
      </div>
    )
  };
}

ScriptEditor.propTypes = {
  mode:PropTypes.string,
  theme:PropTypes.string,
  onChange:PropTypes.func.isRequired,
  value:PropTypes.string
}

export default ScriptEditor;