/**
 * Created by dwiargo on 1/23/18.
 */

import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import PropTypes from 'prop-types';

import './Article.scss';

class Article extends Component{
  render(){
    let { title, info, deck, body, ills, note } = this.props;
    return(
      <div className="mpk-article">
        <div>
          {ills ? (<div className="ills">{ills}</div>) : (null)}
          <div className="content">
            <div className="title">{title}</div>
            {info ? (<div className="info mpk-font-size-N">{info}</div>) : (null)}
            {deck ? (
              <div className="deck decorative-text">{renderHTML(deck)}</div>
            ) : (null)}
            <div className="body body-text mpk-body">{renderHTML(body)}</div>
            {note ? (<div className="note mpk-font-color-D3 mpk-font-size-NS">{note}</div>) : (null)}
          </div>
        </div>
      </div>
    )
  }
}

Article.propTypes = {
  ills:PropTypes.any,
  title:PropTypes.string.isRequired,
  info:PropTypes.string,
  deck:PropTypes.string,
  body:PropTypes.string.isRequired,
  note:PropTypes.string
};

export default Article;