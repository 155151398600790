/**
 * Created by dwiargo on 1/5/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, FontIcon } from 'react-md';
import './SearchField.scss';

class SearchField extends Component {
  constructor(){
    super();
    this.state = {
      keyword:''
    };
  }

  onSubmit = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onSubmit(this.state.keyword);
  }

  render(){
    let { placeholder, withBorder, asPanel, keyword, className, ...inputStyle } = this.props;

    return(
      <form 
        className={`mpk-search-field mpk-layout align-center ${withBorder ? "border" : ""} ${asPanel ? "as-panel" : ""} ${className}`}
        onSubmit={this.onSubmit}  
      >
        <div className="flex">
          <TextField
            id="mpk-search-field-input"
            onChange={text => this.setState({keyword:text})}
            placeholder={placeholder ? placeholder : "search"}
            defaultValue={keyword}
            {...inputStyle}
          />
        </div>
        <div className="flex-none">
          {
            withBorder ? (
              <Button
                flat primary
                disabled={false}
                type="submit"
              >
                <FontIcon iconClassName="mdi mdi-magnify"/>
              </Button>
            ) : (
              <Button
                icon primary
                iconClassName="mdi mdi-magnify"
                disabled={false}
                type="submit"
                />
            )
          }
        </div>
      </form>
    )
  }
}

SearchField.defaultProps = {
  className:''
}

SearchField.propTypes = {
  placeholder:PropTypes.string,
  onSubmit:PropTypes.func.isRequired,
  withBorder:PropTypes.bool,
  keyword:PropTypes.string,
};

export default SearchField;