/**
 * Created by dwiargo on 2/26/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'react-md';
import rdxConnect from '../../hoc/rdxConnect';
import './StatusInfo.scss';

class StatusInfo extends Component{
  render(){
    let { status, message, global, icon, className } = this.props;
    let getMessage = () => {
      return typeof message === 'string' ? message : (global ? message[global.localeCode] : 'message is an object but global props is not found');
    };

    let getIcon = () => {
      switch (status){
        case 'error':
          return <FontIcon className="mdi mdi-alert"/>;
        case 'success':
          return <FontIcon className="mdi mdi-check"/>;
        default :
          return icon ? <FontIcon className={icon}/> : null;
      }
    }

    return(
      <div className={`mpk-status-info flex-none mpk-layout align-center ${status} ${className}`}>
        {getIcon()}
        <div className="flex">{getMessage()}</div>
      </div>
    )
  }
}

StatusInfo.propTypes = {
  message:PropTypes.any.isRequired,
  status:PropTypes.string,
}

StatusInfo.defaultProps = {
  className: ''
}

export default rdxConnect(StatusInfo);