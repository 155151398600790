/**
 * Created by dwiargo on 12/5/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontIcon, MenuButton, ListItem, Divider } from 'react-md';
import authService from '../../services/authService';
import DialogConfirm from '../Dialog/DialogConfirm';
import DialogAlert from '../Dialog/DialogAlert';
import LocaleSwitcher from '../LocaleSwitcher';
import Logo from '../Logo';
import AppLogo from '../AppLogo';
import env from '../../config/env';

import './AppbarProfile.scss';
import MitraDJP from '../MitraDJP/MitraDJP';
import PictureProfile from '../PictureProfile';
import { concat } from 'lodash';

class AppbarProfile extends Component{
  constructor(){
    super();
    this.state = {
      showLogoutDialog:false,
      showAppInfo:false,
      appsShortcut:[],
      menus:[]
    }
  }

  componentDidMount(){
    let _menus = this.props.menus.concat([
      {
        label:'Logout',
        materialIcon:'power_settings_new',
        onClick:() => {this.setState({showLogoutDialog:true})}
      },
      {
        label:'divider'
      },
      {
        label:'App Info',
        materialIcon:'info',
        onClick:() => {this.setState({showAppInfo:true})}
      }
    ]);

    let appsShortcut = this.props.appsShortcut;
    let _appsShortcut = appsShortcut && appsShortcut.length > 0 ? appsShortcut.map((d, i) => (
      <ListItem
        className="apps-shortcut"
        key={i}
        primaryText={d.label}
        leftIcon={d.img ? <img src={d.img} role="presentation" alt={d.label}/> : null}
        onClick={() => {
            window.open(d.url, '_blank');
        }}
        />
    )) : [];

    let consoleShortcut = this.props.consoleShortcut;
    if(consoleShortcut && consoleShortcut.length > 0){
      if(_appsShortcut.length > 0)_appsShortcut.push(<Divider key="divider"/>);
      for(var i = 0 ; i < consoleShortcut.length ; i++){
        let d = consoleShortcut[i];
        let length = _appsShortcut.length;
        _appsShortcut.push(
          <ListItem
            key={i+length}
            primaryText={d.label}
            onClick={() => {
              window.open(d.url, '_blank')
            }}
          />
        )
      }
    }

    this.setState({
      menus:_menus,
      appsShortcut:_appsShortcut
    })
  }

  onLogout = (callback) => {
    if(this.props.onLogout){
      this.props.onLogout((isError, errorMessage) => {
        if(isError){
          callback(isError, errorMessage)
        } else {
          callback();
          this.setState({showLogoutDialog:false});
        }
      })
    } else {
      authService.logout(() => {
        this.setState({showLogoutDialog:false});
      });
    }
  };

  render(){
    let {user} = this.props.auth;
    // let { appsShortcut } = this.state;
    let { global, appName, appsShortcut=[], consoleShortcut=[] } = this.props;

    const GetLogo = (
      <div>
        { appName !== null && appName !== undefined && appName !== '' ? (
          <AppLogo
            light={false}
            appName={appName}
          />
        ) : (
          <Logo 
            ignore
            containerStyle={{
              display: 'block',
            }}
            style={{
              width:96
            }}
          />
        )}
      </div>
    )

    const AppInfo = (
      <div className="mpk-layout column">
        <div className="mpk-border solid thin top dark mpk-padding-M top bottom">
          {env.theme === env.themes.PAJAKKU ? GetLogo : (
            <div className="mpk-font-size-M mpk-font-weight-B">{env.mappingApps[appName]}</div>
          )}
        </div>
        <div className="mpk-border solid thin top dark mpk-padding-N top bottom">
          <div className="mpk-font-size-M mpk-font-weight-B">{appName ? '' : global.appInfo.name}</div>
          {this.props.fixedVersion ? (
            <div className="mpk-font-size-NS mpk-font-color-D3">{`version ${this.props.version}`}</div>
          ) : (
            <div className="mpk-font-size-NS mpk-font-color-D3">{`version ${this.props.global.appInfo.version}`}</div>
          )}
        </div>
        <div className="mpk-padding-N top bottom">
          <div className="mpk-font-size-S">For more information, please visit:</div>
          <div>
            {(() => {
              switch(env.theme){
                case env.themes.SOBATPAJAK:
                  return (
                    <a href="https://sobatpajak.com" target="_blank" rel="noopener noreferrer">
                      www.sobatpajak.com
                    </a>
                  )
                default:
                  return(
                    <a href="https://pajakku.com" target="_blank" rel="noopener noreferrer">
                      www.pajakku.com
                    </a>
                  )
              }
            })()}
          </div>
        </div>
        <MitraDJP/>
        <div className="mpk-padding-N top bottom mpk-font-size-NS mpk-font-color-D3">
          {`Copyright ${global.timestamp.getFullYear()} ${(() => {
            switch(env.theme){
              case env.themes.SOBATPAJAK:
                return 'PT Nebula Surya Corpora';
              default:
                return 'PT Mitra Pajakku'
            }
          })()} All rights reserved`}
        </div>
      </div>
    )

    return(
      <div className="mpk-appbar-profile mpk-layout align-center">
        <div className="user mpk-layout align-center">
          <PictureProfile src={user.profilePic}/>
          <div className="user-info mpk-margin-S margin-left">
            <div>{user.name.split(' ')[0]}</div>
          </div>
        </div>
        { this.props.actions ? (this.props.actions) : null}
        { this.props.notification ? (this.props.notification) : (null)}
        { this.props.global.localeList.length > 1 ? <LocaleSwitcher/> : null }
        { appsShortcut.length > 0 || consoleShortcut.length > 0 ? (
          <MenuButton
            id="menu-apps"
            icon
            iconClassName="mdi mdi-apps"
            className="appbar-button"
            position={MenuButton.Positions.TOP_RIGHT}
            listHeightRestricted={false}
            menuItems={concat(appsShortcut.filter(d => (d.url ? true : false)).map((d, i) => (
              <ListItem
                className="apps-shortcut"
                key={i}
                primaryText={d.label}
                leftIcon={d.img ? (
                  <img 
                    src={d.img} 
                    role="presentation"
                    style={{width: 48, height: 28,  objectFit: 'contain'}}
                    alt={d.label}
                  />
                ) : null}
                onClick={() => {
                    window.open(d.url, '_blank');
                }}
                />
            )), consoleShortcut.length > 0 ? [<Divider/>] : null, consoleShortcut.map((d, i) => (
              <ListItem
                key={`console-${i}`}
                primaryText={d.label}
                onClick={() => {
                  window.open(d.url, '_blank')
                }}
              />
            )))}
          />
        ) : (null)}
        {this.props.rightComponent}
        <MenuButton
          id="menu-button-2"
          icon
          iconClassName="mdi mdi-dots-vertical"
          className="appbar-button"
          position={MenuButton.Positions.TOP_RIGHT}
          menuItems={this.state.menus.map((menu, i) => {
            return menu.label === 'divider' ? (
              <Divider key={i}/>
            ) : (
              <ListItem
                key={i}
                primaryText={menu.label}
                leftIcon={<FontIcon>{menu.materialIcon}</FontIcon>}
                onClick={() => {
                  if(menu.onClick) menu.onClick();
                }}
              />
            )
          })}
        />
        <DialogConfirm
          title="word.logout"
          visible={this.state.showLogoutDialog}
          onSubmit={this.onLogout}
          onCancel={() => this.setState({showLogoutDialog:false})}
          message="sentence.confirm.logout"
          translate={true}
          global={this.props.global}
        />
        <DialogAlert
          title="App Info"
          visible={this.state.showAppInfo}
          onSubmit={this.onLogout}
          onCancel={() => this.setState({showAppInfo:false})}
          message={AppInfo}
          global={this.props.global}
          size="auto"
          dialogStyle={{
            width: '100%',
            maxWidth: 400
          }}
          />
      </div>
    )
  }
}

AppbarProfile.propTypes = {
  menus:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.string.isRequired,
      materialIcon:PropTypes.string.isRequired,
      onClick:PropTypes.func.isRequired
    })
  ),
  appsShortcut:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.string.isRequired,
      img:PropTypes.string,
      onClick:PropTypes.func
    })
  ),
  consoleShortcut:PropTypes.arrayOf(
    PropTypes.shape({
      label:PropTypes.string.isRequired,
      onClick:PropTypes.func
    })
  )
}

export default AppbarProfile;