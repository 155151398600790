import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

const path = '/user_group';
export const get = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const getById = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`)
  return httpService.get(options);
}

export const post = (data) => {
  let options = utilsService.generateGatewayOptions(`${path}`)
  options.data = data;
  return httpService.post(options);
}

export const put = (id, data) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`)
  options.data = data;
  return httpService.put(options);
}

export const remove = item => {
  let options = utilsService.generateGatewayOptions(`${path}/${item.id}`)
  return httpService.delete(options);
}

export const getMember = (id, params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/member`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const postMember = (id, data) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/member/add`)
  options.data = data;
  return httpService.post(options);
}

export const removeMember = (id, item) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/member/${item.id}/remove`)
  return httpService.delete(options);
}

export const searchUserByEmail = (id, email) => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}/member/${email}/search`);
  return httpService.get(options);
}