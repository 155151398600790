import React from 'react';
import * as Wrapper from 'libs/react-mpk/components/Wrapper';
import t from 'counterpart';
import { httpService, rdxConnect } from 'libs/react-mpk';
import * as service from './service';

class TransactionTypeForm extends React.Component{
  render(){
    const hasPermission = this.props.auth.hasPermission('PUT:/transaction_type/{id}','POST:/transaction_type')
    return(
      <Wrapper.Form
        title={t.translate('routes.setting.transactionType.formTitle')}
        columnValidations={['name', 'code']}
        definitions={[
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.name'),
            key: 'name',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.code'),
            key: 'code',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.debitCredit'),
            key: 'debitCredit',
            required: true,
            readOnly: !hasPermission
          },
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.description'),
            key: 'description',
            rows: 2,
            readOnly: !hasPermission
          }
        ]}
        onBeforeChange={(key, value) => {
          if(key === 'code') value = value.toUpperCase().replace(/ /g,'');
          if(key === 'debitCredit') value = value.replace(/\D/g, '').toUpperCase().replace(/ /g, '');
          return value;
        }}
        onSubmit={async (formData, isEdit, callback) => {
          try {
            let res = isEdit ? await service.put(formData.id, formData) : await service.post(formData);
            this.props.tableActions.addDataItem(res.data);
            callback(false, t.translate('sentence.info.savedItem'));
          } catch (error) {
            callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode));
          }
        }}
        {...this.props}
      />
    )
  }
}

export default rdxConnect(TransactionTypeForm);