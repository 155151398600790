/**
 * Created by dwiargo on 12/7/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DialogContainer, Button, LinearProgress } from 'react-md';
import Translate from 'react-translate-component';
import StatusInfo from '../StatusInfo';
import counterpart from 'counterpart';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as toastActions from '../../redux/actions/toastActions';

const defaultState = () => ({
  onProgress:false,
  isError:false,
  errorMessage:''
});

class DialogConfirm extends Component {
  constructor(){
    super();
    this.state = defaultState();
  }

  onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({onProgress:true, isError:false});
    this.props.onSubmit((isError, message, closeOnSuccess=true) => {
      if(isError){
        this.setState({
          onProgress: false,
          isError:true,
          errorMessage:message
        });
      } else {
        this.setState(defaultState());
        if(closeOnSuccess) {
          this.props.onCancel();
          if(message) this.props.toastActions.izi(
            counterpart.translate('word.success'),
            message,
            'success'
          )
        }
      }
    });
  };

  onCancel = () => {
    this.setState(defaultState());
    this.props.onCancel();
  };

  render(){
    let {title, visible, translate, message, global, cancelLabel, submitLabel, ...otherProps} = this.props;
    let {onProgress, isError, errorMessage} = this.state;
    return(
      <DialogContainer
        initialFocus="mpk"
        focusOnMount={false}
        id="mpk-dialog-confirm"
        className="mpk-dialog"
        onHide={() => {}}
        onShow={()=>{
          this.setState({
            isError:false,
            errorMessage:''
          })
        }}
        title={translate ? counterpart.translate(title) : title}
        visible={visible}
        {...otherProps}
        actions={[
          <Button flat secondary onClick={this.onCancel} disabled={onProgress}>
            {cancelLabel ? cancelLabel : <Translate content={'word.no'}/>}
          </Button>,
          <Button flat primary onClick={this.onSubmit} disabled={onProgress}>
          {submitLabel ? submitLabel : <Translate content={'word.yes'}/>}
        </Button>
        ]}
        >
        {onProgress ? (
          <LinearProgress id="mpk-dialog-form-progress"/>
        ) : (null)}
        <div className="mpk-layout column fill">
          {isError ? (
            <div className="mpk-position mpk-margin-N bottom sticky" style={{zIndex:10}}>
              <StatusInfo
                status="error"
                message={errorMessage}
                global={global}
                />
            </div>
          ) : (null)}
          <form className="mpk-content fill" onSubmit={this.onSubmit}>
            <div className="body-text">{ translate ? (<Translate content={message}/>) : (message)}</div>
            <button type="submit" disabled={onProgress} style={{opacity:0}}/>
          </form>
        </div>
      </DialogContainer>
    )
  }
}

DialogConfirm.propTypes = {
  visible:PropTypes.bool.isRequired,
  title:PropTypes.string.isRequired,
  message:PropTypes.string.isRequired,
  onCancel:PropTypes.func,
  onSubmit:PropTypes.func,
  translate:PropTypes.bool
};

const mapStateToProps = (state, props) => {
  return {}
};

const mapDispatchToProps = (dispatch, props) => ({
  toastActions:bindActionCreators(toastActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogConfirm);