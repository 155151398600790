import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

const path = '/transaction';
export const get = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const getById = id => {
  let options = utilsService.generateGatewayOptions(`${path}/${id}`)
  return httpService.get(options);
}

export const getRefund = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/refund`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const getTopUp = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/top_up`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}

export const getPayment = (params, onAddDataItem) => {
  let options = utilsService.generateGatewayOptions(`${path}/payment`)
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddDataItem)
}