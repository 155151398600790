/**
 * Created by dwiargo on 12/5/17.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MenuButton, ListItemControl, Checkbox, FontIcon } from 'react-md';
import counterpart  from 'counterpart'
import localStorageService from '../../services/localStorageService';

import * as globalActions from '../../redux/actions/globalActions';

class LocaleSwitcher extends Component{
  componentWillMount(){
    counterpart.setLocale(this.props.global.localeCode);
  }

  changeHandler = menu => {
    this.props.globalActions.setProperties({localeCode:menu.code});
    localStorageService.set('lang', menu.code);
    counterpart.setLocale(menu.code);
  };

  render(){
    let menus = this.props.global.localeList;

    let {global} = this.props;

    return(
      <div className="mpk-locale-switcher">
        <MenuButton
          id="mpk-locale-switcher"
          icon
          iconClassName="mdi mdi-earth"
          className="appbar-button"
          position={MenuButton.Positions.TOP_RIGHT}
          menuItems={menus.map((menu, i) => (
            <ListItemControl
              key={i}
              primaryAction={(
                <Checkbox
                  id={"mpk-list-tr-"+menu.code}
                  name="list-control-primary"
                  label={menu.label}
                  checked={menu.code === global.localeCode}
                  onChange={() => this.changeHandler(menu)}
                  checkedIcon={<FontIcon>check_circle</FontIcon>}
                  uncheckedIcon={<FontIcon>radio_button_unchecked</FontIcon>}
                />
              )}

            />
          ))}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  global:state.global
});

const mapDispatchToPros = dispatch => ({
  globalActions:bindActionCreators(globalActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToPros)(LocaleSwitcher);