import { httpService } from 'libs/react-mpk';
import { utilsService } from 'services';

export const getUserBalance = () => {
  let options = utilsService.generateGatewayOptions(`/user_balance`);
  return httpService.get(options);
}

export const getTransaction = (params, onAddItem) => {
  let options = utilsService.generateGatewayOptions(`/user_transaction`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddItem);
}

export const getRefund = (params, onAddItem) => {
  let options = utilsService.generateGatewayOptions(`/user_refund`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddItem);
}

export const postRefund = (data) => {
  let options = utilsService.generateGatewayOptions(`/user_refund`);
  options.data = data;
  return httpService.post(options);
}

export const getBank = (params, onAddItem) => {
  let options = utilsService.generateGatewayOptions(`/user_bank`);
  options.params = utilsService.mapUrlParams(params);
  return httpService.streamGet(options, onAddItem);
}

export const postBank = (data) => {
  let options = utilsService.generateGatewayOptions(`/user_bank`);
  options.data = data;
  return httpService.post(options);
}

export const putBank = (id, data) => {
  let options = utilsService.generateGatewayOptions(`/user_bank/${id}`);
  options.data = data;
  return httpService.put(options);
}

export const deleteBank = (id) => {
  let options = utilsService.generateGatewayOptions(`/user_bank/${id}`);
  return httpService.delete(options);
}

export const postPin = (data) => {
  let options = utilsService.generateGatewayOptions(`/user_pin`);
  options.data = data;
  return httpService.post(options);
}