/**
 * Created by dwiargo on 12/19/17.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './RelativeGrid.scss';

class RelativeGrid extends Component{
  render(){
    let {nodes, maxColumn, columnWidth, children} = this.props;

    return(
      <div
        className="mpk-relative-grid"
        style={{
          columnCount:maxColumn,
          columnWidth:columnWidth
        }}
      >
        {nodes.map((d, i) => (
          <div className="figure" key={i}>
            {d}
          </div>
        ))}
        {children}
      </div>
    )
  }
}

RelativeGrid.propTypes = {
  maxColumn:PropTypes.number.isRequired,
  columnWidth:PropTypes.number.isRequired,
  nodes:PropTypes.arrayOf(
    PropTypes.node
  ).isRequired
};

export default RelativeGrid;