import React from 'react';
import tableWrapper from '../../../../../components/tableWrapper';
import t from 'counterpart';
import * as service from '../service';

class Bank extends React.Component{
  render(){
    return(
      <div className="mpk-full height mpk-padding-N padding-all">
        {this.props.children}
      </div>
    )
  }
}

export default tableWrapper((props, handle) => {
  let { userBalanceAccount } = props.tmpObject;
  return {
    hint: {
      children:(
        <div>{t.translate('routes.userBalance.balanceAccount.hint.bankText').replace('*name', userBalanceAccount.userInfo.username)}</div>
      )
    },
    columns: [
      {
        label: t.translate('column.requestDateTime'),
        value: 'dateTime',
        isSearchable: false,
        isSortable: true
      },
      {
        label: t.translate('column.bank'),
        value: 'bank',
        isSearchable: true,
        isSortable: true
      },
      {
        label: t.translate('column.accountNo'),
        value: 'accountNo',
        isSearchable: true,
        isSortable: true
      },
      {
        label: t.translate('column.accountName'),
        value: 'accountName',
        isSearchable: true,
        isSortable: true
      }
    ],
    itemActions: [
      {
        label:"Edit",
        iconClassName:"mdi mdi-pencil",
        onClick: item => handle.showForm(true, item),
      }
    ],
    fetchData: (params, onDataItem) => {
      return new Promise(async (resolve, reject) => {
        let { userBalanceAccount } = props.tmpObject;
        try {
          let res = await service.getBank(userBalanceAccount.accountInfo.id, params, onDataItem);
          resolve({data: [], total: Number(res.headers['x-pagination-count'])});
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
  }
})(Bank)