import React from 'react';
import { DialogContainer, LinearProgress } from 'react-md';
import './Dialog.scss';

const DialogContainerWrapper = (props) => {
  let { onProgress, children, className='', ...others} = props;
  return (
    <DialogContainer
      {...others}
      aria-label={others.ariaLabel || 'mpk-dialog-container'}
      className={`mpk-dialog-container ${className}`}
    >
      {onProgress ? (
        <LinearProgress id="mpk-dialog-container-progress"/>
      ) : (null)}
      {children}
    </DialogContainer>
  )
}

export default DialogContainerWrapper;