import React from 'react';
import detailsWrapper from '../../../components/detailsWrapper';
import Profile from '../Profile';
import t from 'counterpart';

import Transaction from './Transaction';
import * as Refund from './Refund';
import * as BankAccount from './BankAccount';

class Details extends React.Component{
  render(){
    return(
      <div className="mpk-full height mpk-layout column">
        <div className="flex-none">
          <Profile
            showDetailsButton={false}
          />
        </div>
        <div className="flex">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default detailsWrapper(props => ({
  tabs:[
    {
      label: t.translate('routes.widget.transactionHistory'),
      key: 'transaction',
      resourceUri: '',
      component: <Transaction/>,
    },
    {
      label: t.translate('routes.widget.refund.title'),
      key: 'refund',
      resourceUri: '',
      component: <Refund.List/>,
    },
    {
      label: t.translate('routes.widget.destinationAccount'),
      key: 'destination-account',
      resourceUri: '',
      component: <BankAccount.List/>,
    }
  ],
  onTabChange: (tab) => {
    // props.router.history.push(`/widget/details/${tab.key}`)
  },
  onInit: async (callback) => {
    setTimeout(callback)
  },
  fitContainer: true,
  className: 'mpk-full height'
}))(Details);