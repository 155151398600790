/**
 * Created by dwiargo on 2/21/18.
 */

import queryString from 'query-string';
import navService from './navService';

const locationService = {
  redirect:(redirectUri, code, state) => {
    let parsedUrl = queryString.parseUrl(redirectUri);
    parsedUrl.query.code = code;
    parsedUrl.query.state = state;

    let finalUri = parsedUrl.url + '?' + queryString.stringify(parsedUrl.query);
    window.open(finalUri, '_self');
  },
  errorPage:(err, redirectUri, messageKey) => {
    if(err.response && err.response.headers) {
      let authenticateErr = err.response.headers['www-authenticate'] || err.response.headers['WWW-Authenticate'];
      if(authenticateErr) {
        authenticateErr = authenticateErr.replace(/, /g, '&').replace(/"/g, '');
        let parsed = queryString.parse(authenticateErr);
        err.response.message = parsed.error_description || parsed.error;
        err.message = err.message || err.response.message
        err.response.statusText = err.response.status === 401 ? 'Unauthorized' : 'Bad Request';
      }
    }

    let response = err.response || err;
    let data = response.data || null
    let status = err.status || err.statusCode || response.status || response.statusCode;
    let statusText =  response.statusText || 'Undefined';
    let message = err.message || response.message  || (data ? (data.errorMessage || data.error_description || data.message) : (err.message || 'Something wrong, please contact administrator'));
    
    message = typeof message === 'object' ? message[messageKey] : message;

    let parseLocation = queryString.parseUrl(window.location.href);
    redirectUri = redirectUri || parseLocation.query.redirectUri || window.location.origin;
    redirectUri = redirectUri === '/error' ? '/' : redirectUri;
    
    navService.redirectTo('/error?'+queryString.stringify({
      status:status,
      statusText:statusText,
      errorMessage:message,
      redirectUri
    }))
  }
}

export default locationService;