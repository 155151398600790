import t from 'counterpart';

const formService = {
  defaultErrorValidation: (keys, ignoreKeys = []) => {
    let error = {};
    keys = Array.isArray(keys) ? keys : Object.keys(keys);
    for(let key of keys){
      if(ignoreKeys.indexOf(key) < 0){
        error[key] = {
          isError: false,
          errorText: ''
        }
      }
    }
    return error;
  },
  handleChange: (formData, key, value) => {
    let keys = key.split('.');
    if(keys.length > 1){
      let target = formData[keys[0]];
      target[keys[1]] = value;
    } else {
      formData[key] = value;
    }

    return formData;
  },
  validation: (formData, errorValidation, notRequired=[]) => {
    return new Promise((resolve, reject) => {
      let valid = true;
      
      for(let key of Object.keys(errorValidation)){
        if(notRequired.indexOf(key) < 0){
          if(['', undefined, null ].indexOf(formData[key]) >= 0 ? true : false){
            errorValidation[key].isError = true;
            errorValidation[key].errorText = t.translate('sentence.form.requiredField');
            valid = false;
          } else {
            errorValidation[key].isError = false;
            errorValidation[key].errorText = '';
          }
        } else {
          errorValidation[key].isError = false;
          errorValidation[key].errorText = '';
        }
      }
      if(valid) resolve({errorValidation});
      else reject({
        message: t.translate('sentence.form.invalid'),
        errorValidation
      })
    })
  },
  parseErrorRequest: (errorResponnse, errorValidation, localeId) => {
    let { status, data } = errorResponnse;
    if(status === 400 && data && data.error){
      let keys = Object.keys(errorValidation);
      for(let key of keys){
        let errorTarget = data.error[key];
        if(errorTarget){
          errorValidation[key].isError = true;
          errorValidation[key].errorText = localeId ? errorTarget[0].errorMessage[localeId] : errorTarget[0].errorMessage;
        } else {
          errorValidation[key].isError = false;
          errorValidation[key].errorText = false;
        }
      }
      return {errorValidation, message: t.translate('sentence.form.invalid')};
    } else {
      return null;
    }
  } 
}

export default formService;