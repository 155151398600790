import React from 'react';
import tableWrapper from 'components/tableWrapper';
import t from 'counterpart';
import * as service from '../service';
import MemberForm from './MemberForm';
import PictureProfile from '../../../../../libs/react-mpk/components/PictureProfile';

class Member extends React.Component{
  render(){
    return(
      <div className="mpk-full height mpk-padding-N padding-all">
        {this.props.children}
        <MemberForm
          visible={this.props.state.showForm}
          onCancel={() => this.props.handle.showForm(false)}
        />
      </div>
    )
  }
}

export default tableWrapper((props, handle) => {
  return {
    hint:{
      children: t.translate('routes.setting.userGroup.hint.memberText')
    },
    columns: [
      {
        label: t.translate('column.profilePic'),
        value: d => (
          <PictureProfile
            src={d.profile.profilePic}
          />
        ),
        type:'func',
        isSearchable: false,
        isSortable: false,
        show: true
      },
      {
        label: t.translate('column.name'),
        value: 'profile.name',
        isSearchable: true,
        isSortable: true,
        show: true
      },
      {
        label: t.translate('column.username'),
        value: 'profile.username',
        isSearchable: true,
        isSortable: true,
        show: true
      },
      {
        label: t.translate('column.email'),
        value: 'profile.email',
        isSearchable: true,
        isSortable: true,
        show: true
      }
    ],
    itemActions: [
      {
        label:t.translate('word.delete'),
        iconClassName:"mdi mdi-delete",
        onClick: item => {
          handle.deleteItem(item, () => {
            let { userGroup } = props.tmpObject;
            return new Promise(async (resolve, reject) => {
              try {
                await service.removeMember(userGroup.id, item);
                resolve()
              } catch (error) {
                reject(error)
              }
            })
          })
        },
        show: props.auth.hasPermission(`DELETE:/user_group/{id}/member/{memberId}/remove`)
      }
    ],
    actions: [
      {
        label: t.translate('word.add'),
        iconClassName: 'mdi mdi-plus',
        onClick: () => handle.showForm(true),
        show: props.auth.hasPermission('POST:/user_group/{id}/member/add')
      }
    ],
    fetchData: (params, onDataItem) => {
      let { userGroup } = props.tmpObject;
      return new Promise(async (resolve, reject) => {
        try {
          let res = await service.getMember(userGroup.id, params, onDataItem)
          resolve({data: [], total: Number(res.headers['x-pagination-count'])});
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
  }
})(Member)