/**
 * Created by dwiargo on 11/30/17.
 */

import * as types from './actionTypes';

export const setData = (data = []) => {
  return {
    type:types.TABLE_SET_DATA,
    data
  }
};

export const setColumns = (columns = []) => {
  return {
    type:types.TABLE_SET_COLUMNS,
    columns
  }
};

export const setProperties = (properties = {}, resetParams = false) => {
  return {
    type:types.TABLE_SET_PROPERTIES,
    properties, resetParams
  }
};

export const updateParams = (params = {}) => {
  return {
    type:types.TABLE_UPDATE_PARAMS,
    params
  }
};

export const updateColumn = (column, update) => {
  return {
    type:types.TABLE_UPDATE_COLUMN,
    column, update
  }
};


export const addDataItem = (item, disabledTotalIncrement = false) => {
  return {
    type:types.TABLE_ADD_DATA_ITEM,
    item, disabledTotalIncrement
  }
};

export const deleteDataItem = (item) => {
  return {
    type:types.TABLE_DELETE_DATA_ITEM,
    item
  }
};

export const deleteDataItemWithSelector = (selector) => {
  return {
    type:types.TABLE_DELETE_DATA_ITEM_WITH_SELECTOR,
    selector
  }
};

export const deleteDataItems = () => {
  return {
    type:types.TABLE_DELETE_DATA_ITEMS
  }
};

export const deleteSelectedDataItems = () => {
  return {
    type:types.TABLE_DELETE_SELECTED_DATA_ITEMS
  }
};

export const updateDataItem = (index, update) => {
  return {
    type:types.TABLE_UPDATE_DATA_ITEM,
    index, update
  }
};

export const updateDataItemWithSelector = (selector, update) => {
  return {
    type:types.TABLE_UPDATE_DATA_ITEM_WITH_SELECTOR,
    selector, update
  }
};

export const replaceDataItem = (index, update) => {
  return {
    type:types.TABLE_REPLACE_DATA_ITEM,
    index, update
  }
};

export const selectDataItem = (index, isSelect) => {
  return {
    type:types.TABLE_SELECT_DATA_ITEM,
    index, isSelect
  }
};

export const deselectDataItem = () => {
  return {
    type:types.TABLE_DESELECT_DATA_ITEM
  }
}

export const resetAll = () => {
  return {
    type:types.TABLE_RESET_ALL
  }
};

export const reset = (keys = []) => {
  return {
    type:types.TABLE_RESET,
    keys
  }
};

export const resetParams = () => {
  return {
    type:types.TABLE_RESET_PARAMS
  }
};

export const reload = () => {
  return {
    type: types.TABLE_RELOAD_DATA
  }
}