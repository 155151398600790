import React from 'react';
import t from 'counterpart';
import detailsWrapper from '../../../../../components/detailsWrapper';
import { Commandbar, httpService, navService } from '../../../../../libs/react-mpk';
import { Button } from 'react-md';
import * as service from '../service';

import Profile from './Profile';
import { List as TransactionList, Refund } from '../../Transaction';
import Bank from './Bank';

class BalanceAccountDetails extends React.Component{
  componentWillUnmount(){
    this.props.tmpObjectActions.setProperties({userBalanceAccount: null});
  }
  render(){
    let { userBalanceAccount } = this.props.tmpObject;
    return(
      <div className="flex mpk-layout column">
        <Commandbar
          title={userBalanceAccount ? `${userBalanceAccount.accountInfo.accountNo}` : '...'}
          breadcrumbs={[
            t.translate('routes.userBalance.title'),
            t.translate('routes.userBalance.balanceAccount.title')
          ]}
          leftCorner={(
            <Button
              icon iconClassName="mdi mdi-arrow-left"
              onClick={() => navService.redirectTo('/main/user/balance-account-list')}
              />
          )}
        />
        {this.props.children}
      </div>
    )
  }
}

export default detailsWrapper((props) => ({
  tabs:[
    {
      label: t.translate('column.profile'),
      key: 'profile',
      component: <Profile/>,
    },
    {
      label: t.translate('column.transaction'),
      key: 'transaction',
      resourceUri: '',
      component: <TransactionList/>
    },
    {
      label: t.translate('column.refund'),
      key: 'refund',
      resourceUri: '',
      component: <Refund/>,
    },
    {
      label: t.translate('column.bank'),
      key: 'bank',
      resourceUri: '',
      component: <Bank/>,
    }
  ],
  onTabChange: (tab) => {
    props.router.history.push(`/main/user/balance-account/${props.router.match.params.id}/${tab.key}`)
  },
  onInit: async (callback) => {
    try {
      let res = await service.getById(props.router.match.params.id);
      props.tmpObjectActions.setProperties({userBalanceAccount: res.data});
      callback();
    } catch (error) {
      props.toastActions.izi(httpService.utils.parseErrorMessage(error));
      navService.redirectTo(`/main/user/balance-acount-list`)
    }
  }
}))(BalanceAccountDetails);