import React from 'react';
import * as Wrapper from 'libs/react-mpk/components/Wrapper';
import t from 'counterpart';
import { httpService, rdxConnect } from 'libs/react-mpk';
import * as service from '../service';

class Form extends React.Component{
  render(){
    const hasPermission = this.props.auth.hasPermission('POST:/user_group/{id}/member/add');

    return(
      <Wrapper.Form
        title={t.translate('routes.setting.userGroup.memberFormTitle')}
        columnValidations={['email']}
        item={this.props.item}
        showSubmit={hasPermission}
        definitions={[
          {
            inputType: Wrapper.Form.types.INPUT,
            label: t.translate('column.email'),
            key: 'email',
            required: true,
            readOnly: !hasPermission
          }
        ]}
        onBeforeChange={(key, value) => {
          if(key === 'code') value = value.toUpperCase().replace(/ /g,'');
          if(key === 'transactionFee') value = value.replace(/\D/g, '');
          return value;
        }}
        onSubmit={async (formData, isEdit, callback) => {
          let { userGroup } = this.props.tmpObject;
          try {
            let resEmail = await service.searchUserByEmail(userGroup.id, formData.email);
            let res = await service.postMember(userGroup.id, resEmail.data);
            this.props.tableActions.addDataItem(res.data);
            callback(false, t.translate('sentence.info.savedItem'));
          } catch (error) {
            callback(true, httpService.utils.parseErrorMessage(error, this.props.global.localeCode));
          }
        }}
        {...this.props}
      />
    )
  }
}

export default rdxConnect(Form);