/**
 * Created by dwiargo on 10/17/17.
 */

let history = null;
let globalActions = null;

const navService = {
  init:(_history, _globalActions) => {
    history = _history;
    globalActions = _globalActions;
    if(globalActions) globalActions.setProperties({currentPath:window.location.pathname})
  },
  redirectTo:(targetPath) => {
    history.push(targetPath);
    if(globalActions) globalActions.setProperties({currentPath:window.location.pathname})
  },
  back:() => {
    history.goBack();
    if(globalActions) globalActions.setProperties({currentPath:window.location.pathname})
  }
};

export default navService;