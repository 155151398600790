import React from 'react';
import { tableWrapper } from 'components';
import { Commandbar, navService } from '../../../../libs/react-mpk';
import { Switch } from 'react-md';
import t from 'counterpart';
import * as service from './service';
import Form from './Form';

const UserGroupList = props => {
  return(
    <div className="flex mpk-layout column">
      <Commandbar
        title={t.translate('routes.setting.userGroup.title')}
        breadcrumbs={[t.translate('routes.setting.title')]}
        actions={[
          {
            label:'word.add',
            iconClassName:'mdi mdi-plus',
            onClick: () => props.handle.showForm(),
            show: props.auth.hasPermission('POST:/user_group')
          },
          {
            label:'word.reload',
            iconClassName:'mdi mdi-reload',
            onClick: props.tableActions.reload,
          }
        ]}
        rightCorner={(
          <Switch
            id="switch-table-setting"
            name="Switch Table Setting"
            label=""
            checked={props.state.showFilter}
            onChange={props.handle.toggleFilter}
          />
        )}
        translate={false}
      />
      <div className="mpk-padding-N padding-all flex">
        {props.children}
      </div>
      <Form
        visible={props.state.showForm}
        item={props.state.selectedItem}
        onCancel={() => {
          props.handle.showForm(false)
        }}
      />
    </div>
  )
}

export default tableWrapper((props, handle) => {
  return {
    hint:{
      children:t.translate('routes.setting.userGroup.hint.text')
    },
    columns:[
      {label: t.translate('column.name'), value: 'name', show: true, isSearchable: true, isSortable: true, onClick: item => navService.redirectTo(`/main/setting/user-group/${item.id}/profile`)},
      {label: t.translate('column.code'), value: 'code', show: true, isSearchable: true, isSortable: true},
      {label: t.translate('column.description'), value: 'description', className: 'min-width-N', show: true, isSearchable: true, isSortable: true},
    ],
    itemActions: [
      {
        label:"Edit",
        iconClassName:"mdi mdi-pencil",
        onClick: item => handle.showForm(true, item),
        show: props.auth.hasPermission('PUT:/user_group/{id}')
      },
      {
        label:"Delete",
        iconClassName:"mdi mdi-delete",
        onClick: item => handle.deleteItem(item, service.remove, {id: item.id}),
        show: props.auth.hasPermission('DELETE:/user_group/{id}')
      },
    ],
    fetchData: (params, onDataItem) => {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await service.get(params, onDataItem);
          resolve({data: [], total: Number(res.headers['x-pagination-count'])});
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
    tableProps:{
      selectableRows: false
    }
  }
})(UserGroupList)