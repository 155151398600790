/**
 * Created by dwiargo on 1/2/18.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import './TextEditor.scss';

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'align'
]


class TextEditor extends Component{
  constructor(props){
    super(props);
    let { customTools=[], onImage } = props; 
    this.state = {
      val:''
    }
    this.modules = {
      toolbar: { 
        container:[
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'align': ''}, { 'align': 'center'}, { 'align': 'right'}, { 'align': 'justify'}],
            [{ 'script': 'sub'}, { 'script': 'super' }],   
            ['link', 'image'],
            ['clean'],
            [...customTools.map( d => (d.id))]
          ],
        handlers:{}
      }
    }

    if(onImage) this.modules.toolbar.handlers.image = this.imageHandler;
    for(let tool of customTools){
      this.modules.toolbar.handlers[tool.id] = () => {
        this.quillRef.focus();
        let quill = this.quillRef.getEditor();
        const range = quill.getSelection();

        tool.onClick(quill, range)
        // Please Read Documentation
        // https://quilljs.com/docs/api/
        // ***
      }
    }
  }

  componentDidMount(){
    this.quill = this.quillRef;
    this.setState({
      val:this.props.value || '',
      quill: this.quill
    })
    this.generateCustomTools();
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      val:nextProps.value || ''
    })
  }

  generateCustomTools = () => {
    let { customTools } = this.props;
    for(let tool of customTools){
      let elements = document.getElementsByClassName(`ql-${tool.id}`)
      for(let elem of elements){
        elem.innerHTML = tool.component;
      }
    }
  }

  onChange = val => {
    this.setState({val:val})
    if(this.props.onChange) this.props.onChange(val);
  }

  imageHandler = () => {
    this.props.onImage( url => {
      this.quillRef.focus();
      let quill = this.quillRef.getEditor();
      const range = quill.getSelection();
      if(url){
        quill.insertEmbed(range.index, 'image', url, Quill.sources.USER)
      }
    });
  }

  render(){
    let { label, error, errorText, placeholder, onImage, customTools=[] } = this.props;
    let { val } = this.state;
    const modules = {
      toolbar: { 
        container:[
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            [{ 'align': ''}, { 'align': 'center'}, { 'align': 'right'}, { 'align': 'justify'}],
            [{ 'script': 'sub'}, { 'script': 'super' }],   
            ['link', 'image'],
            ['clean'],
            [...customTools.map( d => (d.id))]
          ],
        handlers:{}
      }
    }

    if(onImage) modules.toolbar.handlers.image = this.imageHandler;

    return(
      <div className="mpk-text-editor">
        <label>{label}</label>
        <ReactQuill
          onChange={this.onChange}
          placeholder={placeholder}
          modules={this.modules}
          formats={formats}
          value={val}
          ref={el => {
            this.quillRef = el
          }}
        />
        {error ? (
          <div className="error-info">{errorText}</div>
        ) : (null)}
      </div>
    )
  }
}

TextEditor.defaultProps = {
  customTools: []
}

TextEditor.propTypes = {
  label:PropTypes.string,
  value:PropTypes.any,
  placeholder:PropTypes.string,
  onChange:PropTypes.func,
  onImage:PropTypes.func,
  error:PropTypes.bool,
  errorText:PropTypes.string,
  disabled:PropTypes.bool,
};

export default TextEditor;