/**
 * Created by dwiargo on 7/17/17.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Empty extends Component{
  render(){
    return (
      <div
        className={this.props.className}
        style={{height:this.props.height,width:this.props.width}}
      />
    )
  }
}

Empty.propTypes = {
  width:PropTypes.number,
  height:PropTypes.number
};

export default Empty;
